import React from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';
import Profile from "./Profile";
import Stats from "./Stats";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';
import BottomBar from "../../ui/BottomBar";
import UpdatedAt from "../../ui/UpdatedAt";
import PwaInstall from "../../ui/PwaInstall";
import {TransitionGroup} from "react-transition-group";

const useStyle = makeStyles({
    contenedor: {
        backgroundColor: '#FEFEFE',
        height: '100%',
        position: 'relative',
        paddingTop: 80,
        paddingBottom: 70,
        marginBottom: 70

    },
    profile_container: {
        paddingRight: '5pt'
    }
    ,

    logo: {
        maxHeight: 67,
        height: '100%'
    },
    logo_container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '5%'
    },
    sub_container: {
        //  padding:'3%',
        marginBottom:'10%'
    }
})
export default function Home() {

    const history = useHistory();
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const style = useStyle();

    return <Grid container className={style.contenedor}>
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={2}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12} className={style.profile_container}>
                    <Profile/>
                </Grid>
                <Grid item xs={12}>
                    <Stats/>
                </Grid>
                <Grid item xs={12}>
                    <UpdatedAt/>
                </Grid>
            </Grid>
            <BottomBar/>
        </Grid>

    </Grid>
}