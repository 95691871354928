export const XEL_COLORS = {
    primary: '#5A3571',
    secondary: '#FF8A21',
    secondary_light: '#f9ae39',
    primary_light: '#b2a1bd',
    lila: '#ddd8e4'
}

export const XEL_FONT = {
    name: `'Montserrat', sans-serif;`,
    light_weight: '300',
    regular_weight: '400',
    semi_bold_weight: '600',
    bold_weight: '700',
}

export const OPENPAY={
    PRODUCTION_ID:'msot6vrhy0qdhuqbpqnp',
    PRODUCTION_API_KEY:'pk_06fc6aeb78834947a59885bacda18e50',
    SANDBOX_ID:'msyrfhdmmzbm9qiuhmso',
    SANDBOX_API_KEY:'pk_4b8eadee297548f082aa7ca600cecccd'
}

export const API = 'https://inxel.mx/api/xel/v1';
export const API_SANDBOX = 'https://inxel.mx/api/xel/v1/test';