//Iniciar sesión
export const SET_PRE_BUYDET = (id, pay, product) => {
    return {
        type: "SET_PRE_BUYDET",
        payload: {
            id,
            pay,
            product
        }
    };
};

//Iniciar sesión
export const LOG_IN = (token, tk, flag) => {
    return {
        type: "SIGN_IN",
        payload: {
            logged: true,
            token,
            tk,
            flag
        }
    };
};

//Cerrar sesión
export const LOG_OUT = () => {
    return {
        type: "LOG_OUT"
    };
};


//Cargando
export const LOADING = () => {
    return {
        type: "LOADING",
    };
};

//Carga completa
export const NOT_LOADING = () => {
    return {
        type: "NOT_LOADING"
    };
};


export const SET_DATA = (name, second_name, msisdn) => {
    return {
        type: "SET_DATA",
        payload: {
            name,
            second_name,
            msisdn
        }
    };
};

export const SET_DATA_DETAILS = (data) => {
    return {
        type: "SET_DATA_DETAILS",
        payload: data
    };
};

export const SET_RECORDS_DATA = (data) => {
    return {
        type: "SET_RECORDS_DATA",
        payload: {loaded: true, data}
    };
};

//Iniciar sesión
export const SET_MESSAGE = (message, gravity) => {
    return {
        type: "SET_MESSAGE",
        payload: {
            show: true,
            gravity,
            message
        }
    };
};

//Cerrar sesión
export const CLEAN_MESSAGE = () => {
    return {
        type: "CLEAN_MESSAGE"
    };
};
