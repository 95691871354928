import React from "react";
import Paper from "@material-ui/core/Paper";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constants";
import Button from "@material-ui/core/Button";
import {useHistory} from 'react-router-dom';

const useStyle = makeStyles({
    card: {
        maxWidth: '218pt',
        width: '100%',
        padding: '1%',
        borderRadius: '17pt',
        paddingTop: '3 %',
        paddingBottom: '3%',
    },
    card_container: {
        display: 'flex',
        justifyContent: 'center'
    },
    month_card: {
        color: XEL_COLORS.primary,
        fontSize: '12pt',
        fontWeight: XEL_FONT.bold_weight
    },
    date_card: {
        color: '#707070',
        fontSize: '12pt',
        fontWeight: XEL_FONT.regular_weight
    },
    title_card: {
        color: XEL_COLORS.secondary,
        fontSize: '15pt',
        fontWeight: XEL_FONT.bold_weight
    },
    price_card: {
        color: '#707070',
        fontSize: '15pt',
        fontWeight: XEL_FONT.regular_weight
    },
    button_container_card: {
        display: 'flex',
        justifyContent: 'center'
    },
    button_card: {
        backgroundColor: XEL_COLORS.primary,
        color: '#FEFEFE',
        fontSize: '10pt',
        height: '16pt',
        width: '72pt',
        borderRadius: '8pt'
    }
})
export default function DateCard({date, total, month}) {
    const style = useStyle();
    const history = useHistory();
    const handleToPay = () => {
        history.push('/pagar');
    }
    return <Paper elevation={3} className={style.card}>
        <Grid container spacing={1}>

            <Grid item xs={12}>
                <Typography className={style.title_card} align={'center'}>
                    SALDO A PAGAR
                </Typography>
                <Typography className={style.price_card} align={'center'}>
                    {`$ ${total}.00`}
                </Typography>
            </Grid>
            <Grid item xs={12} className={style.button_container_card}>
                <Button className={style.button_card} onClick={handleToPay}>
                    Pagar
                </Button>
            </Grid>
        </Grid>
    </Paper>
}
