import {API} from "../../constants";
import Cookie from 'js-cookie';
import Axios from "axios";
import AxiosXel from "../AxiosXel";

export default async function profileService(token) {
    const login = new Promise((resolve, reject) => {

        const axiosXel = Axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "https://api.inxel.mx/",
            //   timeout: 1000,
            headers: {'auth-token': token}
        });
        axiosXel.get('/clients/user.profile.get').then(res => {
            console.log(res);
            const response = res.data;
            if (response.error) {
                reject({
                    status: 'ERROR',
                    description: response.error_description,
                    code: response.error_code,
                    token: response.error_code === 400 ? response.token : '',
                });
                return;
            }
            resolve({
                status: 'OK',
                data: response,
                //headertk: response.tk
            });
        }).catch(err => {
            console.log(err)
            reject({
                status: 'ERROR',
                description: "La sesión ha expirado",
                code: 500,
                token: ''
            });
        });
    });

    const result = login.then(response => {
        return response;
    }).catch(error => {
        return error;
    });

    return await result;
}
