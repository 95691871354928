const messagesReducer = (state = {
    show: false,
    gravity: '',
    message: ''
}, action) => {
    switch (action.type) {
        case "SET_MESSAGE":
            return (state = action.payload);
        case "CLEAN_MESSAGE":
            return (state = {
                show: false,
                gravity: '',
                message: ''
            });
        default:
            return state;
    }
};

export default messagesReducer;