import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import Logo from '../../assets/home/img/logo.png';
import Grid from "@material-ui/core/Grid";
import {XEL_COLORS} from "../../constants";
import {NavLink} from "react-router-dom";
import {
    XelIconBarCode,
    XelIconBarConsume,
    XelIconBarHelp,
    XelIconBarInfo,
    XelIconBarStatus
} from "../../assets/home/svg";

const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        maxWidth: 450,
        left: 'auto',
        right: 'auto',
        position: 'fixed',
        backgroundColor: '#FEFEFE',
        zIndex:100
    },
    toolbar: {
        height: 36,
        minHeight: 36,
        padding: 0
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    button_container: {
        backgroundColor: XEL_COLORS.primary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    buttons_container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#FEFEFE'
    },
    button_inter: {
        padding: 1
    }
}));

const XelLink = ({to, logo, type}) => {
    const Logo = logo;
    const classes = useStyles();
    return <NavLink to={to} className={classes.button_container} activeStyle={{
        backgroundColor: '#9C7FBB'
    }}>

        <Logo style={{height: 25, stroke: 'none',fill:'#FEFEFE'}}/>

    </NavLink>
}

export default function BottomBar() {

    const classes = useStyles();
    const initialSize = document.getElementById('super') ? document.getElementById('super').getBoundingClientRect().width : 0;
    const [size, setSize] = useState(initialSize);
    useEffect(() => {
        window.addEventListener('resize', () => {
            setSize(document.getElementById('super').getBoundingClientRect().width);
        });
        return () => {
            window.removeEventListener('resize', () => {
            });
        }
    }, []);
    return <AppBar position="fixed" color="primary" className={classes.appBar} style={{width: size}}>
        <Toolbar className={classes.toolbar}>
            <Grid container className={classes.buttons_container}>
                <Grid item xs className={classes.button}>
                    <XelLink to={"/inicio"} logo={XelIconBarInfo} />
                </Grid>
                <Grid item xs className={classes.button}>
                    <XelLink to={"/consumo"} logo={XelIconBarConsume} />
                </Grid>
                <Grid item xs className={classes.button}>
                    <XelLink to={"/pagar"} logo={XelIconBarStatus} />
                </Grid>
                <Grid item xs className={classes.button}>
                    <XelLink to={"/referencia"} logo={XelIconBarCode} />
                </Grid>
                <Grid item xs>
                    <XelLink to={"/ayuda"} logo={XelIconBarHelp} />
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
}