import React, {useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constants";
import Button from "@material-ui/core/Button";
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles({
    iddle_border: {
        border: '1px solid #BBBBBB'
    },
    selected_border: {
        border: '1px solid #FF8A21'
    },
    container: {
        borderRadius: 10,
        padding: '2%',
        position: 'relative'
    },
    border_button: {
        borderRadius: '50%',
        height: 35,
        width: 35,
        padding: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circle_button: {
        height: '100%',
        width: '100%',
        borderRadius: '50%',
        backgroundColor: '#FEFEFE'
    },
    selected_circle_button: {
        backgroundColor: '#FF8A21'
    }
    ,
    center_c: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hotspot: {
        position: 'absolute',
        top: '-15%',
        right: '-3%',
        //  bottom: '-5.2%',
        background: XEL_COLORS.secondary,
        padding: 1,
        borderRadius: '50%',
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    hotspot_sel: {
        background: XEL_COLORS.primary
    }
});
export default function Prepago({selected, data, onClick}) {
    const styles = useStyles();
    const isSelected = !selected ? false : selected;
    const history = useHistory();
    return <Grid style={{position: 'relative'}}
                 container
                 justify={'center'}
                 onClick={() => {
                     if (onClick) {
                         onClick();
                     }
                 }}>

        <Grid item xs={10}
              className={`${isSelected ? styles.selected_border : styles.iddle_border} ${styles.container}`}>

            <Grid container justify={'center'}>
                {
                    data.hotspot === 1 ? <div className={`${styles.hotspot} ${isSelected ? styles.hotspot_sel : ""}`}>
                        <Typography style={{fontSize: 7, color: "#FEFEFE"}} align={'center'}>
                            Hostspot habilitado
                        </Typography>
                    </div> : ""
                }
                <Grid item xs={3}
                      className={styles.center_c}
                    //      className={`${isSelected ? styles.selected_border : styles.iddle_border} ${styles.b}`}
                >
                    <div
                        className={`${isSelected ? styles.selected_border : styles.iddle_border} ${styles.border_button}`}>
                        <span
                            className={`${isSelected ? styles.selected_border : styles.iddle_border} ${styles.circle_button} ${isSelected ? styles.selected_circle_button : ""}`}
                        >
                        </span>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 12}}>
                                {data.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 10}}>
                                {data.public_description}
                            </Typography>
                        </Grid>
                        {
                            data.local_minutes ? <Grid item xs={12}>
                                <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 10}}>
                                    {data.local_minutes} Minutos
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            data.roaming_minutes ? <Grid item xs={12}>
                                <Typography style={{fontSize: 10}}>
                                    {data.roaming_minutes} Minutos en roaming internacional
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            data.local_sms ? <Grid item xs={12}>
                                <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 10}}>
                                    {data.local_sms} SMS
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            data.roaming_sms ? <Grid item xs={12}>
                                <Typography style={{fontSize: 10}}>
                                    {data.roaming_sms} SMS en roaming internacional
                                </Typography>
                            </Grid> : ""
                        }
                    </Grid>
                </Grid>
                <Grid item xs={2}
                      className={styles.center_c}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography align={'center'} style={{fontSize: 12, fontWeight: XEL_FONT.bold_weight}}>
                                ${data.price}
                            </Typography>
                            <Typography align={'center'} style={{fontSize: 10}}>
                                30 dias
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {
            isSelected ? <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                <Button onClick={() => {
                    history.push('/pagar-ahora/prepago')
                }} style={{fontSize: 12, marginTop: '3%'}} variant={'contained'} color={'primary'}>
                    Continuar
                </Button>
            </Grid> : ""
        }
    </Grid>
}