import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Grid, withStyles} from "@material-ui/core";
import {XEL_COLORS} from "../../constants";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {styled} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';
import Cookie from "js-cookie";

const useStyle = makeStyles({
    nav_bar: {
        height: 70,
        position: 'fixed',
        top: 0,
        zIndex: 30,
        backgroundColor: '#FEFEFE',
        padding: '3%',
        left: 'auto',
        bottom: 'auto',
        right: 'auto',
        boxShadow: ' 0px 10px 5px 0px rgba(255,255,255,1)'
    },
    burger_grid_container: {
        display: 'flex',
        justifyContent: 'flex-start',

    },

    burger_div_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        paddingTop: '3%',
        paddingBottom: '3%',
        widht: 40,
        cursor: 'pointer',
    },
    line_burger: {
        height: 7,
        width: 40,
        backgroundColor: XEL_COLORS.primary,
        borderRadius: 4
    },
    for_icon: {
        fontSize: 35
    },
    icon_container: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    icon_button: {
        height: '100%'
    },
    fixed_container: {
        position: 'fixed',
        top: 0
    }
});

const BurgerLine = styled('span')({
    height: 7,
    width: 40,
    backgroundColor: XEL_COLORS.primary,
    borderRadius: 4
});

export default function NavBar() {
    const style = useStyle();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const initialSize = document.getElementById('super') ? document.getElementById('super').getBoundingClientRect().width : 0;
    const [size, setSize] = useState(initialSize);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setSize(document.getElementById('super').getBoundingClientRect().width);
        });
        return () => {
            window.removeEventListener('resize', () => {
            });
        }
    }, []);

    const handleMenu = () => {
        if (location.pathname === '/menu') {
            window.history.back();
        } else {
            history.push('/menu')
        }
    }
    const handleReload = () => {
        Cookie.remove('saved_data');
        Cookie.remove('prepagos');
        dispatch(Actions.LOG_IN(session.token, !session.flag));
    }

    return <Grid container className={style.nav_bar} style={{width: size}}>
        <Grid item xs className={style.burger_grid_container}>
            <div className={style.burger_div_container} onClick={handleMenu}>
                <BurgerLine/>
                <BurgerLine/>
                <BurgerLine/>
            </div>
        </Grid>
        <Grid item xs className={style.icon_container}>
            <IconButton onClick={handleReload} color="primary" aria-label="Recargar" className={style.icon_button}>
                <AutorenewIcon className={style.for_icon}/>
            </IconButton>
        </Grid>

    </Grid>
}