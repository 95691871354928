import React, {useEffect, useState} from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {useSelector} from "react-redux";
import Axios from "axios";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        backgroundColor: "#FEFEFE",
        borderRadius: 10,
        height: '96%',
        padding: '1%'
    }
})
export default function Details({open, setOpen}) {
    const styles = useStyles();

    const profileDetail = useSelector(state => state.profiledetail);
    const [lineDet, setLineDet] = useState({
        loaded: false,
    });

    useEffect(() => {
        Axios.get('https://api.inxel.mx/clients/ciclos.get/' + parseInt(profileDetail.date_cut)).then(response => {
            setLineDet({
                ...lineDet,
                ...response.data
            });
            console.log(response)
        }).catch(err => {
            console.log(err);
        })
        console.log(profileDetail);
    }, [])


    const handleClose = () => {
        setOpen(false);
    }
    return <Backdrop style={{display: 'flex', position: 'absolute', height: '100%', width: '100%', zIndex: 10000}}
                     open={open}
                     onClick={handleClose}>
        <Grid container justify={'center'} style={{height: '100%'}} alignItems={'center'}>
            <Grid item xs={11} className={styles.container}>

                <Grid container spacing={2} style={{marginTop: '2.5%'}}>
                    <Grid item xs={12}>
                        <Typography align={'center'} variant={'h6'} color={'primary'}>
                            Detalles de ciclo
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} color={'primary'} style={{fontSize: 15}}>
                            Fecha de Corte
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} style={{fontSize: 15}}>
                            {lineDet.FeCo} de cada mes
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} color={'primary'} style={{fontSize: 15}}>
                            Fecha limite de pago
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} style={{fontSize: 15}}>
                            {lineDet.FeLi} de cada mes
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{marginTop: '2.5%'}}>
                    <Grid item xs={12}>
                        <Typography align={'center'} variant={'h6'} color={'primary'}>
                            Cobros domiciliados
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} color={'primary'} style={{fontSize: 15}}>
                            Primer intento
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} style={{fontSize: 15}}>
                            {lineDet.FirstTry} de cada mes
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} color={'primary'} style={{fontSize: 15}}>
                            Segundo intento
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'center'} style={{fontSize: 15}}>
                            {lineDet.SecondTry} de cada mes
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Backdrop>

}