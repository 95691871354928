import React from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';
import InboxIcon from '@material-ui/icons/Inbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';
import Typography from "@material-ui/core/Typography";
import BottomBar from "../../ui/BottomBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DraftsIcon from '@material-ui/icons/Drafts';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
    XelIconApp,
    XelIconCallXel,
    XelIconCellPhone,
    XelIconLegal,
    XelIconMail,
    XelIconOnlineChat
} from "../../assets/home/svg";

const useStyle = makeStyles({
        contenedor: {
            backgroundColor: '#FEFEFE',
            height: '100%',
            position: 'relative',
            paddingTop: 80,

        },
        logo: {
            maxHeight: 67,
            height: '100%'
        },
        logo_container: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '5%'
        },
        border_line: {
            backgroundColor: XEL_COLORS.primary,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        border_text: {
            color: '#FEFEFE',
            fontSize: 15,
            fontWeight: XEL_FONT.bold_weight
        },
        list_container: {
            marginTop: '15%',
            paddingLeft: '5%'
        },
        item_text: {
            color: "#707070",

            fontSize: '15pt',

        },
        list_item: {
            borderBottom: '1px solid #000000',
            display: 'flex',
            justifyContent: 'center'
        },
        option: {
            display: 'flex',
            justifyContent: "space-between",
            borderBottom: '1px solid #000000',
            cursor: 'pointer',
            alignItems: 'center',
            padding: '5%',
            paddingLeft: '20%',
            paddingRight: '15%',
        },
        option_icon: {
            stroke: 'none',
            fill: XEL_COLORS.primary,
            height: '32pt',
            marginRight: '5%',
            //justifySelf:'flex-start'
        },
        next: {
            marginLeft: '5%',
            marginRight: 0
        },
        sub_container: {
            marginBottom: 50
        }
    }
)
const Option = ({text, path, inapp, Icon, iconHeight}) => {
    const history = useHistory();
    const style = useStyle();
    return <Grid container>
        <Grid item xs={12} className={style.option} onClick={() => {
            if (inapp) {
                history.push(path);
            } else {
                window.open(path, '_blank');
            }
        }}>
            <Icon className={style.option_icon} style={{height: `${iconHeight}pt`}}/>
            <Typography className={style.item_text}>
                {text}
            </Typography>
            <ArrowForwardIosIcon className={`${style.option_icon} ${style.next}`}/>
        </Grid>
    </Grid>
}
export default function Help() {

    const history = useHistory();
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const style = useStyle();

    return <Grid container className={style.contenedor}>
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={3}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12} className={style.border_line}>
                    <Typography className={style.border_text}>
                        Ayuda
                    </Typography>
                </Grid>
                <Grid item xs={12} className={style.list_container}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Option text={'Chat en línea'} path={'/chat'} inapp Icon={XelIconOnlineChat}
                                    iconHeight={40}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Option text={'*Xel'} path={'tel:*935'} Icon={XelIconCallXel} iconHeight={40}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Option text={'Teléfono'} path={'tel:55 8840 9522'} Icon={XelIconCellPhone}
                                    iconHeight={41}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Option text={'Correo'} path={'mailto:asistencia@inxel.mx'} Icon={XelIconMail}
                                    iconHeight={25}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Option text={'Legales'} path={'https://inxel.mx#legales'} Icon={XelIconLegal}
                                    iconHeight={35}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <BottomBar/>
        </Grid>
    </Grid>
}