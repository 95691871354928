import React from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';


import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';
import Typography from "@material-ui/core/Typography";
import BottomBar from "../../ui/BottomBar";
import {XelIconPagar, XelIconPayOptions, XelIconRecharge} from "../../assets/home/svg";

const useStyle = makeStyles({
        contenedor: {
            backgroundColor: '#FEFEFE',
            height: '100%',
            position: 'relative',
            paddingTop: 70,
            overflow: 'hidden'
        },


        logo: {
            maxHeight: 67,
            height: '100%'
        },
        logo_container: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '5%'
        },
        border_line: {
            backgroundColor: XEL_COLORS.primary,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        border_text: {
            color: '#FEFEFE',
            fontSize: 15,
            fontWeight: XEL_FONT.bold_weight
        },
        sub_container: {
            //    padding: '1%'
        },
        intro_text: {
            fontSize: '15pt'
        },
        body_container: {
            marginTop: '20'
        },
        option_icon_container: {
            display: 'flex',
            justifyContent: 'center',

        },
        option_icon: {
            stroke: 'none',
            height: '24pt',
            fill: XEL_COLORS.primary
        },
        options_container: {
            marginTop: 30
        },
        option_text: {
            color: '#707070',
            fontSize: '13pt',
            paddingTop: '3%',
            paddingBottom: '3%',
        }, option_container: {
            borderBottom: `1px solid #000000`,
            borderTop: `1px solid #000000`,
            padding: 10,
            cursor: 'pointer'
        }
    }
)

const Option = ({text, handler, Icon}) => {
    const style = useStyle();
    const handleClick = () => {
        handler();
    }
    return <Grid container className={style.option_container} onClick={handleClick}>
        <Grid item xs={3} className={style.option_icon_container}>
            <Icon className={style.option_icon}/>
        </Grid>
        <Grid item xs>
            <Typography className={style.option_text}>
                {text}
            </Typography>
        </Grid>
    </Grid>
}

export default function Services() {

    const history = useHistory();
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const style = useStyle();

    const payAnother = () => {
        history.push('/pagar-ahora/other');
    }

    return <Grid container className={style.contenedor}>
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={3}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12} className={style.border_line}>
                    <Typography className={style.border_text}>
                        Otros Servicios
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={style.body_container}>
                        <Grid item xs={12}>
                            <Typography align={'center'} className={style.intro_text}>
                                ¿Se acabarón tus datos? Recarga aquí directamente, en la App Xel ó contacta a uno de tus
                                asesores.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={style.options_container}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Option text={'Paga otro número inXel'} handler={payAnother}
                                            Icon={XelIconPayOptions}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Option text={'Recargar saldo'} handler={payAnother}
                                            Icon={XelIconRecharge}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <BottomBar/>
        </Grid>
    </Grid>
}