import React from "react";
import {Grid} from "@material-ui/core";
import Avatar from '../../../assets/home/img/avatar.png'
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import {XEL_FONT} from "../../../constants";
import {useSelector} from "react-redux";

const useStyle = makeStyles({
    avatar: {
        maxHeight: 80,
        height: '100%'
    },
    avatar_container: {
        display: 'flex',
        justifyContent: "center",
        height: '100%',
        alignItems: 'center'
    },
    container: {
        display: 'flex'
    },
    text_name: {
        color: '#707070',
        fontSize: '15pt'
    },
    text_title: {
        color: '#707070',
        fontSize: '10pt'
    },
    text_value: {
        color: '#707070',
        fontSize: '10pt',
        fontWeight: XEL_FONT.bold_weight
    },
    container_first: {
        padding: '3%'
    }
});
export default function User() {
    const user = useSelector(state => state.profile)
    const style = useStyle();
    return <Grid container alignItems={'center'} spacing={0} className={style.container_first}>
        <Grid item xs className={style.avatar_container}>
            <img src={Avatar} alt="Avatar" className={style.avatar}/>
        </Grid>
        <Grid item xs>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'body1'} className={style.text_name}>
                        {user.name} {' '}
                    </Typography>
                    <Typography variant={'body2'} className={style.text_title}>
                        {user.msisdn}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}