import React from "react";
import {Grid} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constants";
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles({
    text_title: {
        color: XEL_COLORS.secondary,
        fontSize: '10pt',
        marginBottom:'1.5%'
    },
    text_progress: {
        color: "#000000",
        filter: "invert(100%)",
        fontSize: '10pt',
        position: 'absolute',
        right: 30,
        display: 'flex',
        align: 'center',
        fontWeight: XEL_FONT.bold_weight
    },
    progress_container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '24pt'
    }
});

export default function ProgressLine({title, porcent,body}) {
    const style = useStyle();
    return <Grid container >
        <Grid item xs={12}>
            <Typography className={style.text_title} align={'center'}>
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} className={style.progress_container}>
            <LinearProgress variant="determinate" value={porcent} style={{height: '24pt', width: '100%'}}/>
            <Typography className={style.text_progress}>
                {body}
            </Typography>
        </Grid>
    </Grid>
}