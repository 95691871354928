import React, {useEffect} from 'react';
import logo from '../logo.svg';
import {OPENPAY, XEL_COLORS, XEL_FONT} from "../constants";
import red from "@material-ui/core/colors/red";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Contenedor from "./container";
import {ThemeProvider} from "@material-ui/styles";
import {Provider, useSelector} from "react-redux";
import myStore from "./reducers";
import {BrowserRouter as Router} from "react-router-dom";
import {register} from "../serviceWorker";

/*// Inicializar el proveedor de credenciales de Amazon Cognito
AWS.config.region = ''; // Región
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:89a99ee1-19c3-40ca-8390-65a05d8e5235',
});*/

//Initialize Amplify
//Auth.configure(amplifyConfig);


//Initialize Amplify


/*
const analyticsConfig = {
    AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '2c12eb0df1884b39bb772089a69e2db4',
        // Amazon service region
        region: 'us-west-2',
        mandatorySignIn: false,
    }
}

Analytics.configure(analyticsConfig)

Analytics.updateEndpoint({
    attributes: {
        interests: ['science', 'politics', 'travel'],
        //..
    },
    userId: 'UserIdValue',
    userAttributes: {
        username: 'ilovethecloud'
    }
});*/

const inXelTheme = createMuiTheme({
    palette: {
        primary: {
            main: XEL_COLORS.primary
        },
        secondary: {
            main: XEL_COLORS.secondary
        },
        danger: red[700],
    },
    typography: {
        fontFamily: XEL_FONT.name,
        h1: {
            fontWeight: XEL_FONT.bold_weight,
            fontSize: 36

        },
        h2: {
            fontWeight: XEL_FONT.semi_bold_weight,
            fontSize: 33
        },
        h3: {
            fontWeight: XEL_FONT.semi_bold_weight,
            fontSize: 30
        },
        h4: {
            fontWeight: XEL_FONT.semi_bold_weight,
            fontSize: 27
        },
        h5: {
            fontWeight: XEL_FONT.semi_bold_weight,
            fontSize: 24
        },
        h6: {
            fontWeight: XEL_FONT.semi_bold_weight,
            fontSize: 21
        },
        body1: {
            fontWeight: XEL_FONT.regular_weight,
            fontSize: 18
        },
        body2: {
            fontWeight: XEL_FONT.regular_weight,
            fontSize: 15
        },
        button: {
            fontWeight: XEL_FONT.regular_weight,
            textTransform: 'none',
            fontSize: 18,
        },
        especial: {
            fontWeight: XEL_FONT.regular_weight,
            fontSize: 50,
            textAlign: 'center'
        }

    }
});

function App() {
    useEffect(() => {

        let sandbox = false;
        /***Variables de OPENPAY***/
        // eslint-disable-next-line no-undef
        OpenPay.setId(!sandbox ? OPENPAY.PRODUCTION_ID : OPENPAY.SANDBOX_ID);
        // eslint-disable-next-line no-undef
        OpenPay.setApiKey(!sandbox ? OPENPAY.PRODUCTION_API_KEY : OPENPAY.SANDBOX_API_KEY);
        // eslint-disable-next-line no-undef
        OpenPay.setSandboxMode(sandbox);

    }, []);

    async function handleUpdatePWA() {
        fetch('https://inxel.mx/api/xel/v1/pwa.version').then(res => res.text()).then(res => {
            handleUpdatePWA();
        }).catch(err => {
            console.log(err)
        });

        console.log('Actualizando...');
        if ("serviceWorker" in navigator) {
            let refreshing;

            // check to see if there is a current active service worker
            const oldSw = (await navigator.serviceWorker.getRegistration())?.active?.state;

            navigator.serviceWorker.addEventListener('controllerchange', async () => {
                if (refreshing) return;

                // when the controllerchange event has fired, we get the new service worker
                const newSw = (await navigator.serviceWorker.getRegistration())?.active?.state;

                // if there was already an old activated service worker, and a new activating service worker, do the reload
                if (oldSw === 'activated' && newSw === 'activating') {
                    refreshing = true;
                    window.location.reload();
                }
            });
        }
    }

    return (
        <Router>
            <div className="inXel-client" id={'super'}>
                <Provider store={myStore}>
                    <ThemeProvider theme={inXelTheme}>
                        <Contenedor/>
                    </ThemeProvider>
                </Provider>
            </div>
        </Router>
    );
}


export default App;
