import React from "react";
import {Grid, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../../../constants";
import Typography from "@material-ui/core/Typography";

const useStyle = makeStyles({

    stat_line: props => ({
        backgroundColor: props.color === 'secondary' ? XEL_COLORS.secondary : XEL_COLORS.primary,
        width: '100%',
        height: '32pt',
        display: 'flex',
        alignItems: 'center',
    }),
    title: {
        color: '#FEFEFE',
        fontSize: '14pt',
        width: '100%',
        fontWeight: XEL_FONT.bold_weight
    },
    body_text: {
        color: '#707070',
        fontSize: '13.5pt',
        fontWeight: XEL_FONT.regular_weight,
        marginLeft: '15pt'
    },
    icon: {
        stroke: XEL_COLORS.primary
    },
    body_container: {
        display: 'flex',
        height: '60pt',
        alignItems: 'center',
    },
    icon_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
})
export default function Stat({btn, title, color, icon, text, children}) {
    const props = {color: color};
    const Icon = icon;
    const style = useStyle(props);
    return <Grid container>
        <Grid item xs={12} className={style.stat_line}>
            <Typography align={'center'} className={style.title}>
                {title}
            </Typography>
        </Grid>
        {
            icon ? <Grid item xs={12}>
                <Grid container className={style.body_container}>
                    <Grid item xs={5} className={style.icon_container}>
                        {
                            btn ? <IconButton style={{boxShadow: '1px 1px #000000'}}>
                                    <Icon style={{stroke: 'none', fill: XEL_COLORS.primary, height: '32pt'}}/>
                                </IconButton>
                                : <Icon style={{stroke: 'none', fill: XEL_COLORS.primary, height: '32pt'}}/>
                        }
                    </Grid>
                    <Grid item xs={5}>
                        <Typography className={style.body_text} align={'center'}>
                            {text}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid> : ''
        }
        <Grid item xs={12}>
            {children}
        </Grid>
    </Grid>
}
