import React from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';


import User from "./User";
import {
    XelIconConfiguration,
    XelIconLogout,
    XelIconPagar,
    XelIconPayOptions,
    XelIconServices, XelIconWhats
} from "../../assets/home/svg";
import Typography from "@material-ui/core/Typography";
import Cookie from 'js-cookie';

const useStyle = makeStyles({
    contenedor: {
        backgroundColor: '#FEFEFE',
        height: '100vh',
        position: 'relative',
        paddingTop: 80,
    },
    logo: {
        maxHeight: 67,
        height: '100%'
    },
    logo_container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '5%'
    },
    sub_container: {
        //  padding:'3%'
    },
    option_icon_container: {
        display: 'flex',
        justifyContent: 'flex-end',

    },
    option_icon: {
        height: '24pt',
        stroke: 'none',
        fill: XEL_COLORS.primary
    },
    text: {
        color: '#707070',
        fontSize: '13pt',
        paddingLeft: 40
    },
    options_container: {
        marginTop: 20
    },
    option_container: {
        marginTop: 20,
        marginBottom: 20,
        cursor: 'pointer'
    }
});

const Option = ({Icon, text, handler, iconHeight}) => {
    const style = useStyle();
    const history = useHistory();
    const handleClick = () => {
        handler();
    }
    return <Grid container className={style.option_container} onClick={handleClick}>
        <Grid item xs={4} className={style.option_icon_container}>
            <Icon className={style.option_icon} style={{height: `${iconHeight}pt`}}/>
        </Grid>
        <Grid item xs={6}>
            <Typography className={style.text} align={'left'}>
                {text}
            </Typography>
        </Grid>
    </Grid>
}

export default function Menu() {

    const history = useHistory();
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profiledetail);
    const style = useStyle();

    const payOptionsHandler = () => {
        history.push('/pagar')
    }

    const servicesHandler = () => {
        history.push('/servicios');
    }
    const whatsHandler = () => {

        window.open('https://api.whatsapp.com/send?phone=+525567902404&text=Hola', '_blank');
    }
    const logOut = () => {
        Cookie.remove('xel_tk_client');
        Cookie.remove('saved_data');
        Cookie.remove('saved_profile_details');
        Cookie.remove('saved_profile');
        Cookie.remove('saved_date');
        Cookie.remove('saved_records');
        window.location.reload();
    }
    return <Grid container className={style.contenedor}>
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={2}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12}>
                    <User/>
                </Grid>
            </Grid>
            <Grid container className={style.options_container}>
                <Grid item xs={12}>
                    <Option Icon={XelIconPayOptions} handler={payOptionsHandler}
                            text={`${profile.date_cut === 0 ? "Opciones de recarga" : 'Opciones de pago'} `}
                            iconHeight={24}/>
                </Grid>

                <Grid item xs={12}>
                    <Option Icon={XelIconWhats} handler={whatsHandler} text={'inXelZap'} iconHeight={32}/>
                </Grid>
                {//  <Grid item xs={12}>
                    //<Option Icon={XelIconServices} handler={servicesHandler} text={'Otros Servicios'} iconHeight={32}/>
                    //</Grid>
                    //   <Grid item xs={12}>
                    //                     <Option Icon={XelIconConfiguration} handler={payOptionsHandler} text={'Configuración'}
                    //                             iconHeight={32}/>
                    //                 </Grid>
                }
                <Grid item xs={12}>
                    <Option Icon={XelIconLogout} handler={logOut} text={'Cerrar Sesión'} iconHeight={28}/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}