import React from "react";

export const XelIconWhats = (props) => {
    return <svg viewBox="0 0 512 512" version={'1.0'}  {...props} >
        <path
            d="m439.277344 72.722656c-46.898438-46.898437-109.238282-72.722656-175.566406-72.722656-.003907 0-.019532 0-.023438 0-32.804688.00390625-64.773438 6.355469-95.011719 18.882812-30.242187 12.527344-57.335937 30.640626-80.535156 53.839844-46.894531 46.894532-72.71875 109.246094-72.71875 175.566406 0 39.550782 9.542969 78.855469 27.625 113.875l-41.734375 119.226563c-2.941406 8.410156-.859375 17.550781 5.445312 23.851563 4.410157 4.414062 10.214844 6.757812 16.183594 6.757812 2.558594 0 5.144532-.429688 7.667969-1.3125l119.226563-41.730469c35.019531 18.082031 74.324218 27.625 113.875 27.625 66.320312 0 128.667968-25.828125 175.566406-72.722656 46.894531-46.894531 72.722656-109.246094 72.722656-175.566406 0-66.324219-25.824219-128.675781-72.722656-175.570313zm-21.234375 329.902344c-41.222657 41.226562-96.035157 63.925781-154.332031 63.925781-35.664063 0-71.09375-8.820312-102.460938-25.515625-5.6875-3.023437-12.410156-3.542968-18.445312-1.429687l-108.320313 37.910156 37.914063-108.320313c2.113281-6.042968 1.589843-12.765624-1.433594-18.449218-16.691406-31.359375-25.515625-66.789063-25.515625-102.457032 0-58.296874 22.703125-113.109374 63.925781-154.332031 41.21875-41.21875 96.023438-63.921875 154.316406-63.929687h.019532c58.300781 0 113.109374 22.703125 154.332031 63.929687 41.226562 41.222657 63.929687 96.03125 63.929687 154.332031 0 58.300782-22.703125 113.113282-63.929687 154.335938zm0 0"/>
        <path
            d="m355.984375 270.46875c-11.421875-11.421875-30.007813-11.421875-41.429687 0l-12.492188 12.492188c-31.019531-16.902344-56.121094-42.003907-73.027344-73.023438l12.492188-12.492188c11.425781-11.421874 11.425781-30.007812 0-41.429687l-33.664063-33.664063c-11.421875-11.421874-30.007812-11.421874-41.429687 0l-26.929688 26.929688c-15.425781 15.425781-16.195312 41.945312-2.167968 74.675781 12.179687 28.417969 34.46875 59.652344 62.761718 87.945313 28.292969 28.292968 59.527344 50.582031 87.945313 62.761718 15.550781 6.664063 29.695312 9.988282 41.917969 9.988282 13.503906 0 24.660156-4.058594 32.757812-12.15625l26.929688-26.933594v.003906c5.535156-5.535156 8.582031-12.890625 8.582031-20.714844 0-7.828124-3.046875-15.183593-8.582031-20.714843zm-14.5 80.792969c-4.402344 4.402343-17.941406 5.945312-41.609375-4.195313-24.992188-10.710937-52.886719-30.742187-78.542969-56.398437s-45.683593-53.546875-56.394531-78.539063c-10.144531-23.667968-8.601562-37.210937-4.199219-41.613281l26.414063-26.414063 32.625 32.628907-15.636719 15.640625c-7.070313 7.070312-8.777344 17.792968-4.242187 26.683594 20.558593 40.3125 52.734374 72.488281 93.046874 93.046874 8.894532 4.535157 19.617188 2.832032 26.6875-4.242187l15.636719-15.636719 32.628907 32.628906zm0 0"/>
    </svg>
}

export const XelIconRecharge = (props) => {
    return <svg viewBox="0 0 115.95 101.46" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path
                    d="M112.33,65.22a3.63,3.63,0,0,0-3.62,3.63V94.21H7.25V50.73H47.11a3.63,3.63,0,0,0,0-7.25H7.25V29H47.11a3.63,3.63,0,0,0,0-7.25H7.25A7.26,7.26,0,0,0,0,29V94.21a7.26,7.26,0,0,0,7.25,7.25H108.71A7.25,7.25,0,0,0,116,94.21V68.85A3.63,3.63,0,0,0,112.33,65.22Z"/>
                <path d="M18.12,65.22a3.63,3.63,0,1,0,0,7.25H32.61a3.63,3.63,0,1,0,0-7.25Z"/>
                <path
                    d="M113.76,11.16,88.39.29a3.64,3.64,0,0,0-2.86,0L60.17,11.16A3.62,3.62,0,0,0,58,14.49V29c0,19.93,7.37,31.59,27.18,43a3.63,3.63,0,0,0,3.61,0C108.58,60.61,116,49,116,29V14.49A3.62,3.62,0,0,0,113.76,11.16ZM108.71,29c0,16.73-5.54,25.94-21.74,35.65C70.76,54.91,65.22,45.7,65.22,29V16.88L87,7.56l21.74,9.32Z"/>
                <path
                    d="M79.11,30.6a3.62,3.62,0,0,0-6,4l7.25,10.87a3.62,3.62,0,0,0,2.86,1.61h.15a3.63,3.63,0,0,0,2.84-1.36l14.49-18.12a3.62,3.62,0,0,0-.56-5.09,3.66,3.66,0,0,0-5.1.56L83.62,37.34Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconServices = (props) => {
    return <svg viewBox="0 0 111 111" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path
                    d="M79.29,39V32.28A2.65,2.65,0,0,0,77,29.66L71.38,29l-1.75-4.22,3.46-4.44a2.64,2.64,0,0,0-.21-3.49L62.47,6.41A2.64,2.64,0,0,0,59,6.19L54.54,9.65,50.32,7.9l-.69-5.58A2.65,2.65,0,0,0,47,0H32.28a2.64,2.64,0,0,0-2.62,2.32L29,7.9,24.75,9.65,20.31,6.19a2.63,2.63,0,0,0-3.49.22L6.41,16.82a2.64,2.64,0,0,0-.22,3.49l3.46,4.44L7.91,29l-5.59.69A2.65,2.65,0,0,0,0,32.28V47a2.65,2.65,0,0,0,2.32,2.63l5.58.69,1.75,4.22L6.19,59a2.63,2.63,0,0,0,.22,3.49L16.82,72.88a2.64,2.64,0,0,0,3.49.21l4.44-3.46L29,71.38,29.66,77a2.65,2.65,0,0,0,2.62,2.32H39A36.12,36.12,0,1,0,79.29,39ZM34,69.21a2.65,2.65,0,0,0-1.61-2.12l-7.07-2.93a2.65,2.65,0,0,0-2.64.36l-3.81,3-7.1-7.11,3-3.81a2.63,2.63,0,0,0,.35-2.63L12.2,46.87a2.65,2.65,0,0,0-2.12-1.61l-4.79-.59V34.62L10.08,34a2.68,2.68,0,0,0,2.12-1.61l2.93-7.07a2.67,2.67,0,0,0-.36-2.64l-3-3.81,7.1-7.1,3.81,3a2.65,2.65,0,0,0,2.64.35l7.07-2.92A2.66,2.66,0,0,0,34,10.08l.59-4.79H44.67l.59,4.79a2.66,2.66,0,0,0,1.61,2.12l7.07,2.93a2.65,2.65,0,0,0,2.63-.36l3.81-3,7.11,7.1-3,3.81a2.65,2.65,0,0,0-.36,2.64l2.93,7.07A2.64,2.64,0,0,0,69.21,34l4.79.59v4.15a35.9,35.9,0,0,0-14.44,3.41,20.37,20.37,0,0,0,0-5.29A20.15,20.15,0,1,0,42.18,59.56,35.9,35.9,0,0,0,38.77,74H34.62ZM46.25,52.89a14.8,14.8,0,1,1,6.64-6.64,36.61,36.61,0,0,0-6.64,6.64Zm28.63,52.82a30.83,30.83,0,1,1,30.83-30.83,30.86,30.86,0,0,1-30.83,30.83Z"/>
                <path
                    d="M96,68.71h-4.4V66.07a16.74,16.74,0,0,0-33.48,0v2.64h-4.4a4.43,4.43,0,0,0-4.41,4.41v9.25a4,4,0,0,0,4,4h4.84v.88a7.94,7.94,0,0,0,7.93,7.93H78.4a2.64,2.64,0,1,0,0-5.28H66.07a2.65,2.65,0,0,1-2.64-2.65V66.07a11.45,11.45,0,0,1,22.9,0V83.69A2.65,2.65,0,0,0,89,86.33h7.48a4,4,0,0,0,4-4V73.12A4.42,4.42,0,0,0,96,68.71ZM54.62,74h3.52v7H54.62Zm40.52,7H91.62V74h3.52Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconPayOptions = (props) => {
    return <svg viewBox="0 0 150.74 111.71" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path
                    d="M109.41,97.32a12.58,12.58,0,0,0,7.14-2.19,12.78,12.78,0,1,0,0-21.17,12.59,12.59,0,0,0-7.14-2.2,12.78,12.78,0,0,0,0,25.56Zm0-18.45A5.68,5.68,0,0,1,113.8,81a3.54,3.54,0,0,0,5,.53,3.79,3.79,0,0,0,.53-.53,5.67,5.67,0,1,1,.71,8,4.32,4.32,0,0,1-.74-.74A3.41,3.41,0,0,0,116.55,87h0a3.48,3.48,0,0,0-2.72,1.27,5.53,5.53,0,0,1-4.38,2.05,5.72,5.72,0,0,1-5.73-5.69,5.66,5.66,0,0,1,5.69-5.73Z"/>
                <path
                    d="M150.74,43c0-.35-.14-.67-.14-1V34.4a15,15,0,0,0-15.13-15h-4.31V15.17A15.05,15.05,0,0,0,116.24,0H15A15.1,15.1,0,0,0,0,15.17V77.31a14.93,14.93,0,0,0,14.88,15h4.21v4.28a15.19,15.19,0,0,0,15.1,15.16H135.47A15.25,15.25,0,0,0,150.6,96.55v-36c0-.32.14-.67.14-1s-.14-.67-.14-1V44C150.6,43.66,150.74,43.31,150.74,43ZM19.09,34.4V85.2H15a7.86,7.86,0,0,1-7.92-7.74V15.17A8,8,0,0,1,15,7.07H116.27a8,8,0,0,1,7.85,8.1v4.27H34.19A15,15,0,0,0,19.09,34.4ZM143.53,96.55a8.13,8.13,0,0,1-8.06,8.09H34.19a8.07,8.07,0,0,1-8-8.09V62.93H143.53Zm0-40.69H26.16v-9.2H143.53Zm0-16.27H26.16V34.4a8,8,0,0,1,8-7.89H135.5a8,8,0,0,1,8.06,7.89v5.19Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconConfiguration = (props) => {
    return <svg viewBox="0 0 110.13 111" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path
                    d="M55.07,38.16A17.34,17.34,0,1,0,72.41,55.5,17.36,17.36,0,0,0,55.07,38.16Zm0,26a8.67,8.67,0,1,1,8.67-8.67,8.68,8.68,0,0,1-8.67,8.67Z"/>
                <path
                    d="M108.43,68.43l-11.22-8.6a41.77,41.77,0,0,0,0-8.66l11.22-8.6a4.33,4.33,0,0,0,1.1-5.64L98.66,18.5a4.35,4.35,0,0,0-5.33-1.83L80,21.92a42,42,0,0,0-7.78-4.41l-2-13.8A4.34,4.34,0,0,0,65.94,0H44.19A4.34,4.34,0,0,0,39.9,3.71l-2,13.8a42.34,42.34,0,0,0-7.78,4.41L16.8,16.67a4.34,4.34,0,0,0-5.32,1.83L.6,36.93a4.34,4.34,0,0,0,1.1,5.64l11.23,8.6a40.43,40.43,0,0,0,0,8.66L1.7,68.43A4.34,4.34,0,0,0,.6,74.07L11.47,92.5a4.35,4.35,0,0,0,5.33,1.83l13.32-5.25a42.34,42.34,0,0,0,7.78,4.41l2,13.8A4.34,4.34,0,0,0,44.19,111H65.94a4.34,4.34,0,0,0,4.29-3.71l2-13.8A42,42,0,0,0,80,89.08l13.32,5.25a4.35,4.35,0,0,0,5.33-1.83l10.87-18.43a4.33,4.33,0,0,0-1.1-5.64ZM93.07,84.9,80.92,80.12a4.33,4.33,0,0,0-4.32.67,33.89,33.89,0,0,1-9.78,5.53A4.33,4.33,0,0,0,64,89.77l-1.82,12.56H48L46.13,89.77a4.32,4.32,0,0,0-2.82-3.45,34.16,34.16,0,0,1-9.78-5.53,4.33,4.33,0,0,0-4.32-.67L17.06,84.9,10,73,20.2,65.17A4.34,4.34,0,0,0,21.84,61a32.24,32.24,0,0,1,0-11,4.34,4.34,0,0,0-1.64-4.17L10,38l7-11.92,12.15,4.78a4.33,4.33,0,0,0,4.32-.67,34.16,34.16,0,0,1,9.78-5.53,4.32,4.32,0,0,0,2.82-3.45L48,8.67H62.19L64,21.23a4.33,4.33,0,0,0,2.81,3.45,33.89,33.89,0,0,1,9.78,5.53,4.33,4.33,0,0,0,4.32.67L93.07,26.1l7,11.92L89.93,45.83A4.34,4.34,0,0,0,88.29,50a32.24,32.24,0,0,1,0,11,4.34,4.34,0,0,0,1.64,4.17L100.11,73Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconLogout = (props) => {
    return <svg viewBox="0 0 84.59 100.73" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g className="cls-2">
                    <path
                        d="M63,23.84a3.93,3.93,0,0,0,3.93-3.93V9.71A9.78,9.78,0,0,0,57.11,0H10A9.78,9.78,0,0,0,.2,9.71V91A9.78,9.78,0,0,0,10,100.73H57.11A9.78,9.78,0,0,0,66.93,91V80.82a3.93,3.93,0,0,0-7.86,0V91a1.91,1.91,0,0,1-2,1.85H10A1.9,1.9,0,0,1,8.07,91V9.71A1.9,1.9,0,0,1,10,7.87H57.11a1.91,1.91,0,0,1,2,1.84v10.2A3.93,3.93,0,0,0,63,23.84Z"
                        transform="translate(-0.2)"/>
                    <path
                        d="M83.6,47.55,62.66,27.13a3.93,3.93,0,0,0-5.49,5.63l14,13.67H33.57a3.94,3.94,0,1,0,0,7.87H71.19L57.17,68a3.93,3.93,0,0,0,5.49,5.63L83.6,53.18a3.92,3.92,0,0,0,0-5.63Z"
                        transform="translate(-0.2)"/>
                </g>
            </g>
        </g>
    </svg>
}

export const XelIconCard = (props) => {
    return <svg viewBox="0 0 129.93 86.57" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_38" data-name="Grupo 38">
                    <path id="Trazado_107" data-name="Trazado 107"
                          d="M0,35.41V69A17.54,17.54,0,0,0,17.53,86.57H112.4A17.53,17.53,0,0,0,129.93,69h0V35.41a3,3,0,0,0-3-3H3.05a3,3,0,0,0-3,3ZM32.44,56.75H24.32A5.34,5.34,0,1,1,24,46.08h8.46a5.34,5.34,0,0,1,.34,10.67Z"/>
                    <path id="Trazado_108" data-name="Trazado 108"
                          d="M129.93,18.63V17.55A17.53,17.53,0,0,0,112.41,0H17.54A17.53,17.53,0,0,0,0,17.54v1.08a3,3,0,0,0,3,3.05H126.88a3,3,0,0,0,3.05-3.05Z"/>
                </g>
            </g>
        </g>
    </svg>
}

export const XelIconTrashCan = (props) => {
    return <svg viewBox="0 0 86.4 106.41" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_40" data-name="Grupo 40">
                    <path id="Trazado_109" data-name="Trazado 109"
                          d="M57.93,38.55A2.48,2.48,0,0,0,55.44,41h0v47.1a2.49,2.49,0,0,0,5,0V41a2.49,2.49,0,0,0-2.49-2.5h0Z"/>
                    <path id="Trazado_110" data-name="Trazado 110"
                          d="M28.52,38.55A2.49,2.49,0,0,0,26,41h0v47.1a2.49,2.49,0,0,0,5,0V41a2.49,2.49,0,0,0-2.49-2.5h0Z"/>
                    <path id="Trazado_111" data-name="Trazado 111"
                          d="M7.09,31.67V93.08a13.71,13.71,0,0,0,3.65,9.48,12.2,12.2,0,0,0,8.9,3.85H66.8a12.2,12.2,0,0,0,8.9-3.85,13.76,13.76,0,0,0,3.66-9.48V31.67A9.52,9.52,0,0,0,76.91,13H64.13V9.85A9.79,9.79,0,0,0,54.39,0H32.13a9.79,9.79,0,0,0-9.87,9.72V13H9.48A9.52,9.52,0,0,0,7,31.67ZM66.8,101.43H19.65a7.9,7.9,0,0,1-7.59-8.19.86.86,0,0,0,0-.16V31.89H74.38V93.08A7.89,7.89,0,0,1,67,101.42ZM27.28,9.84A4.79,4.79,0,0,1,32,5a.33.33,0,0,1,.14,0H54.29a4.8,4.8,0,0,1,4.89,4.71V13H27.28ZM9.53,17.94H76.92a4.49,4.49,0,1,1,0,9H9.53a4.49,4.49,0,1,1,0-9Z"/>
                    <path id="Trazado_112" data-name="Trazado 112"
                          d="M43.22,38.55A2.49,2.49,0,0,0,40.73,41h0v47.1a2.5,2.5,0,0,0,5,0V41a2.5,2.5,0,0,0-2.5-2.5h0Z"/>
                </g>
            </g>
        </g>
    </svg>
}

export const XelIconCalendarPoint = (props) => {
    return <svg viewBox="0 0 108 108" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path id="Trazado_27" data-name="Trazado 27"
                      d="M99.56,70V25.31a8.43,8.43,0,0,0-8.44-8.43H85.64V21.1a4.22,4.22,0,0,1-8.44,0V16.88H58V21.1a4.22,4.22,0,0,1-8.44,0V16.88h-19V21.1a4.22,4.22,0,0,1-8.44,0V16.88H16.87a8.42,8.42,0,0,0-8.43,8.43V91.13a8.42,8.42,0,0,0,8.43,8.43H91.12a8.43,8.43,0,0,0,8.44-8.43,4.22,4.22,0,0,1,8.44,0A16.89,16.89,0,0,1,91.12,108H16.87A16.89,16.89,0,0,1,0,91.13V25.31A16.89,16.89,0,0,1,16.87,8.44h5.28V4.22a4.22,4.22,0,1,1,8.44,0h0V8.44h19V4.22a4.22,4.22,0,0,1,8.44,0V8.44H77.2V4.22a4.22,4.22,0,0,1,8.44,0V8.44h5.48A16.89,16.89,0,0,1,108,25.31V70a4.22,4.22,0,0,1-8.44,0Zm-69-25.73a4.22,4.22,0,1,0-8.44,0h0a4.22,4.22,0,1,0,8.44,0Zm36.7,0h0a4.22,4.22,0,0,0-8.44,0h0a4.22,4.22,0,0,0,8.44,0Zm18.35,0a4.22,4.22,0,0,0-8.44,0h0a4.22,4.22,0,1,0,8.44,0Zm-55,18.35a4.22,4.22,0,1,0-8.44,0h0a4.22,4.22,0,1,0,8.44,0ZM26.37,76.78A4.22,4.22,0,0,0,22.15,81h0a4.22,4.22,0,0,0,8.44,0h0A4.22,4.22,0,0,0,26.37,76.78ZM48.94,44.3a4.22,4.22,0,0,0-8.44,0h0a4.22,4.22,0,0,0,8.44,0Zm0,18.35a4.22,4.22,0,0,0-8.44,0h0a4.22,4.22,0,1,0,8.44,0ZM44.72,76.78A4.22,4.22,0,0,0,40.5,81h0a4.22,4.22,0,0,0,8.44,0h0A4.22,4.22,0,0,0,44.72,76.78Zm14.13-9.91a8.44,8.44,0,0,1,8.44-8.44H77.2a8.44,8.44,0,0,1,8.44,8.44v9.91a8.44,8.44,0,0,1-8.44,8.44H67.29a8.44,8.44,0,0,1-8.44-8.44Zm8.44,9.91H77.2V66.87H67.29Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconMail = (props) => {
    return <svg viewBox="0 0 140.36 106.8" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_34" data-name="Grupo 34">
                    <path id="Trazado_95" data-name="Trazado 95"
                          d="M123.22,0H17.15A17.18,17.18,0,0,0,0,17.15V89.64A17.19,17.19,0,0,0,17.15,106.8H123.2a17.19,17.19,0,0,0,17.16-17.16V17.19A17.15,17.15,0,0,0,123.24,0Zm9.34,89.65A9.33,9.33,0,0,1,123.24,99H17.15a9.33,9.33,0,0,1-9.32-9.32V17.19a9.34,9.34,0,0,1,9.32-9.32H123.2a9.33,9.33,0,0,1,9.32,9.32V89.65Z"/>
                    <path id="Trazado_96" data-name="Trazado 96"
                          d="M88.52,52.51,122.83,21.7a3.93,3.93,0,1,0-5.26-5.84h0L70.23,58.35,61,50.12S61,50.07,61,50a5.7,5.7,0,0,0-.64-.56L22.74,15.85a3.93,3.93,0,0,0-5.23,5.85L52.25,52.75,17.66,85.13a3.93,3.93,0,0,0-.18,5.54,4,4,0,0,0,2.88,1.25,3.94,3.94,0,0,0,2.67-1L58.14,58.05l9.52,8.51a3.9,3.9,0,0,0,5.23,0l9.79-8.77,34.9,33.16a3.92,3.92,0,1,0,5.4-5.69Z"/>
                </g>
            </g>
        </g>
    </svg>
}

export const XelIconLegal = (props) => {
    return <svg viewBox="0 0 107.88 108" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_35" data-name="Grupo 35">
                    <path id="Trazado_97" data-name="Trazado 97"
                          d="M41.13,25.53h25.3a3.17,3.17,0,0,0,0-6.33H41.13a3.17,3.17,0,1,0,0,6.33Z"/>
                    <path id="Trazado_98" data-name="Trazado 98"
                          d="M41.13,38.19h25.3a3.17,3.17,0,0,0,0-6.33H41.13a3.17,3.17,0,1,0,0,6.33Z"/>
                    <path id="Trazado_99" data-name="Trazado 99"
                          d="M15.82,50.84H66.44a3.17,3.17,0,0,0,0-6.33H15.82a3.17,3.17,0,0,0,0,6.33Z"/>
                    <path id="Trazado_100" data-name="Trazado 100"
                          d="M15.82,63.5H55a3.17,3.17,0,0,0,0-6.33H15.82a3.17,3.17,0,0,0,0,6.33Z"/>
                    <path id="Trazado_101" data-name="Trazado 101"
                          d="M82.47,57.17V9.49A9.67,9.67,0,0,0,72.77,0H28.47a3.14,3.14,0,0,0-2.24.93L.93,26.46A3.2,3.2,0,0,0,0,28.69V98.51A9.5,9.5,0,0,0,9.49,108h73a25.42,25.42,0,0,0,0-50.83ZM25.3,10.86V25.54H10.75ZM9.48,101.67a3.16,3.16,0,0,1-3.16-3.16V31.87H28.47a3.17,3.17,0,0,0,3.16-3.17V6.34H72.76A3.35,3.35,0,0,1,76.13,9.5V57.94A25.61,25.61,0,0,0,60.37,69.81H15.81a3.17,3.17,0,0,0,0,6.33H57.75a25,25,0,0,0-.81,6.32H15.81a3.17,3.17,0,0,0,0,6.33H57.74a25.67,25.67,0,0,0,8,12.87Zm73,0a19.44,19.44,0,0,1-19.2-19.19,19.2,19.2,0,0,1,38.39,0,19.43,19.43,0,0,1-19.18,19.19Z"/>
                    <path id="Trazado_102" data-name="Trazado 102"
                          d="M90,77.06,79.3,87.55,75,83.37a3.16,3.16,0,0,0-4.4,4.55l6.54,6.32a3.16,3.16,0,0,0,4.42,0L94.4,81.58A3.16,3.16,0,1,0,90,77.07h0Z"/>
                </g>
            </g>
        </g>
    </svg>
}

export const XelIconCellPhone = (props) => {
    return <svg viewBox="0 0 82.2 136.05" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_33" data-name="Grupo 33">
                    <path id="Trazado_92" data-name="Trazado 92"
                          d="M71.75,0H10.45A10.24,10.24,0,0,0,0,10V126.09a10.24,10.24,0,0,0,10.45,10h61.3a10.24,10.24,0,0,0,10.45-10V10A10.24,10.24,0,0,0,71.75,0ZM10.45,4h61.3A6.14,6.14,0,0,1,78,10v6H4.18V10A6.14,6.14,0,0,1,10.45,4Zm61.3,128.08H10.45a6.13,6.13,0,0,1-6.27-6V112.16h6.27a2,2,0,0,0,0-4H4.18V19.93H78v88.22H18.81a2,2,0,0,0,0,4H78v14A6.13,6.13,0,0,1,71.75,132.06Z"/>
                    <path id="Trazado_93" data-name="Trazado 93"
                          d="M41.45,11.64h7.76a1.94,1.94,0,0,0,.16-3.88H41.45a1.94,1.94,0,0,0-.16,3.88Z"/>
                    <path id="Trazado_94" data-name="Trazado 94"
                          d="M41.1,116.65a5.82,5.82,0,1,0,5.82,5.82h0A5.83,5.83,0,0,0,41.1,116.65Zm0,7.76A1.95,1.95,0,1,1,43,122.47h0A1.94,1.94,0,0,1,41.1,124.41Z"/>
                    <ellipse id="Elipse_5" data-name="Elipse 5" cx="33.93" cy="9.82" rx="1.66" ry="3.32"/>
                </g>
            </g>
        </g>
    </svg>
}


export const XelIconCallXel = (props) => {
    return <svg viewBox="0 0 136.36 136.06" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path
                    d="M49.65,39.64a3.5,3.5,0,0,0-.07.65v-.65c0,.22,0,.44,0,.65H37.19c0-.21,0-.43,0-.65Zm49.52.64H86.82c0-.45,0-.9,0-1.36v1.36a3,3,0,0,0-.32-1.36H99.11C99.15,39.37,99.17,39.82,99.17,40.28Zm-62-.23v.24H49.57A21,21,0,0,1,31.09,60.08L18.5,61.33A16.51,16.51,0,0,1,0,44.55V43.39C0,26.27,13.48,9.08,30.1,5c0,0,12.55-5,38.08-5s38.07,5,38.07,5c16.69,3.84,30.11,21.29,30.11,38.42v1.3a16.15,16.15,0,0,1-18.47,16.45l-12.66-1.48a21.48,21.48,0,0,1-18.41-19.4H99.17V38.92H86.45a3.1,3.1,0,0,0-2.78-1.73h-31a3.14,3.14,0,0,0-3,2.45H37.2A15.52,15.52,0,0,1,52.69,24.79h31a15.61,15.61,0,0,1,15.5,14.13,9.06,9.06,0,0,0,7.5,8.45l12.66,1.48c2.84.33,4.63-1.26,4.63-4.14v-1.3c0-11.52-9.59-23.83-20.49-26.34l-.92-.21-.88-.35a48.34,48.34,0,0,0-7.28-1.85A136.31,136.31,0,0,0,68.18,12.4,136.31,136.31,0,0,0,42,14.66a48.24,48.24,0,0,0-7.29,1.85l-.79.31-.82.21C22,19.75,12.4,32,12.4,43.39v1.16A4.15,4.15,0,0,0,17.27,49l12.59-1.25A8.66,8.66,0,0,0,37.19,40.05Z"/>
                <rect x="37.48" y="74.08" width="12.4" height="12.4"/>
                <rect x="37.48" y="98.87" width="12.4" height="12.4"/>
                <rect x="37.48" y="123.67" width="12.4" height="12.4"/>
                <rect x="62.27" y="123.67" width="12.4" height="12.4"/>
                <rect x="87.06" y="123.67" width="12.4" height="12.4"/>
                <rect x="62.27" y="74.08" width="12.4" height="12.4"/>
                <rect x="87.06" y="74.08" width="12.4" height="12.4"/>
                <rect x="62.27" y="98.87" width="12.4" height="12.4"/>
                <rect x="87.06" y="98.87" width="12.4" height="12.4"/>
            </g>
        </g>
    </svg>
}

export const XelIconOnlineChat = (props) => {
    return <svg viewBox="0 0 144.17 144.18" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path id="Trazado_88" data-name="Trazado 88"
                      d="M31.54,36H85.6a4.51,4.51,0,1,0,0-9H31.54a4.51,4.51,0,1,0,0,9Z"/>
                <path id="Trazado_89" data-name="Trazado 89"
                      d="M67.58,54.06a4.51,4.51,0,1,0,0-9h-36a4.51,4.51,0,1,0,0,9Z"/>
                <path id="Trazado_90" data-name="Trazado 90"
                      d="M2.6,112.21a4.47,4.47,0,0,0,1.91.42,4.52,4.52,0,0,0,2.88-1L33.16,90.11h66a18,18,0,0,0,18-18V18a18,18,0,0,0-18-18H18A18,18,0,0,0,0,18v90.11A4.5,4.5,0,0,0,2.6,112.21ZM9,18a9,9,0,0,1,9-9h81.1a9,9,0,0,1,9,9V72.09a9,9,0,0,1-9,9H31.54a4.53,4.53,0,0,0-2.89,1L9,98.51Z"/>
                <path id="Trazado_91" data-name="Trazado 91"
                      d="M126.15,36a4.51,4.51,0,1,0,0,9,9,9,0,0,1,9,9v76.22L120,118.12a4.49,4.49,0,0,0-2.81-1H54.06a9,9,0,0,1-9-9v-4.51a4.51,4.51,0,0,0-9,0v4.51a18,18,0,0,0,18,18h61.49l21.3,17a4.51,4.51,0,0,0,7.32-3.53V54.06A18,18,0,0,0,126.15,36Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconLocation = (props) => {
    return <svg viewBox="0 0 90.8 130.97" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path
                    d="M90.8,45.4A45.4,45.4,0,0,0,0,45.4C0,55.64,7.18,72.51,21.35,95.56c7.32,11.9,14.71,22.49,18.45,27.73h-16V131H67v-7.68H51c3.74-5.24,11.13-15.83,18.45-27.73C83.62,72.51,90.8,55.64,90.8,45.4ZM45.4,118C32.56,100,7.68,61.75,7.68,45.4a37.72,37.72,0,0,1,75.44,0C83.12,61.74,58.24,100,45.4,118Z"/>
                <path
                    d="M45.4,21.39A23.31,23.31,0,1,0,68.7,44.7,23.33,23.33,0,0,0,45.4,21.39Zm0,38.93A15.63,15.63,0,1,1,61,44.7,15.64,15.64,0,0,1,45.4,60.32Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconPaySign = (props) => {
    return <svg viewBox="0 0 90.06 90.06" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_8" data-name="Grupo 8">
                    <path id="Trazado_13" data-name="Trazado 13"
                          d="M76.87,13.19A45,45,0,1,0,90.06,45,45,45,0,0,0,76.87,13.19ZM45,84.79A39.76,39.76,0,1,1,84.79,45h0A39.81,39.81,0,0,1,45,84.79Z"/>
                    <path id="Trazado_14" data-name="Trazado 14"
                          d="M47.86,42.39H42.21a5.84,5.84,0,1,1,0-11.68H53.51a2.64,2.64,0,0,0,0-5.27H47.67V19.6a2.64,2.64,0,0,0-5.28,0v5.84H42.2a11.12,11.12,0,0,0,0,22.23h5.65a5.84,5.84,0,0,1,0,11.68H36.55a2.64,2.64,0,0,0,0,5.28h5.84v5.84a2.64,2.64,0,0,0,5.28,0V64.63h.18a11.12,11.12,0,0,0,0-22.24Z"/>
                </g>
            </g>
        </g>
    </svg>
}

export const XelIconCalendar = (props) => {
    return <svg viewBox="0 0 90.26 90.26" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path id="Trazado_15" data-name="Trazado 15"
                      d="M86.73,62.05a3.53,3.53,0,0,0,3.53-3.52V21.15a14.12,14.12,0,0,0-14.1-14.1H71.57V3.53a3.53,3.53,0,1,0-7,0V7.05h-16V3.53a3.53,3.53,0,1,0-7,0V7.05H25.56V3.53a3.53,3.53,0,1,0-7,0V7.05H14.1A14.12,14.12,0,0,0,0,21.15v55a14.12,14.12,0,0,0,14.1,14.1H76.16a14.12,14.12,0,0,0,14.1-14.1,3.53,3.53,0,1,0-7.05,0,7.05,7.05,0,0,1-7,7H14.1a7.05,7.05,0,0,1-7-7v-55a7.05,7.05,0,0,1,7.05-7h4.41v3.53a3.53,3.53,0,0,0,7,0V14.1H41.43v3.53a3.53,3.53,0,0,0,7,0V14.1h16v3.53a3.53,3.53,0,0,0,7,0V14.1h4.59a7.05,7.05,0,0,1,7,7V58.53A3.53,3.53,0,0,0,86.73,62.05Z"/>
                <path id="Trazado_16" data-name="Trazado 16" d="M25.56,37a3.53,3.53,0,1,0-7,0h0a3.53,3.53,0,1,0,7,0Z"/>
                <path id="Trazado_17" data-name="Trazado 17" d="M56.23,37a3.53,3.53,0,1,0-7,0h0a3.53,3.53,0,1,0,7,0Z"/>
                <path id="Trazado_18" data-name="Trazado 18" d="M71.57,37a3.53,3.53,0,1,0-7,0h0a3.53,3.53,0,1,0,7,0Z"/>
                <path id="Trazado_19" data-name="Trazado 19"
                      d="M25.56,52.36a3.53,3.53,0,1,0-7,0h0a3.53,3.53,0,0,0,7,0Z"/>
                <path id="Trazado_20" data-name="Trazado 20"
                      d="M22,64.17a3.53,3.53,0,0,0-3.52,3.52h0a3.53,3.53,0,1,0,7,0h0A3.53,3.53,0,0,0,22,64.17Z"/>
                <path id="Trazado_21" data-name="Trazado 21" d="M40.9,37a3.53,3.53,0,1,0-7,0h0a3.53,3.53,0,1,0,7,0Z"/>
                <path id="Trazado_22" data-name="Trazado 22"
                      d="M40.9,52.36a3.53,3.53,0,1,0-7,0h0a3.53,3.53,0,0,0,7,0Z"/>
                <path id="Trazado_23" data-name="Trazado 23"
                      d="M37.37,64.17a3.53,3.53,0,0,0-3.52,3.52h0a3.53,3.53,0,1,0,7,0h0A3.53,3.53,0,0,0,37.37,64.17Z"/>
                <path id="Trazado_24" data-name="Trazado 24"
                      d="M56.23,52.36a3.53,3.53,0,1,0-7,0h0a3.53,3.53,0,0,0,7,0Z"/>
                <path id="Trazado_25" data-name="Trazado 25"
                      d="M68.05,48.83a3.53,3.53,0,0,0-3.53,3.53h0a3.53,3.53,0,0,0,7,0h0A3.53,3.53,0,0,0,68.05,48.83Z"/>
                <path id="Trazado_26" data-name="Trazado 26"
                      d="M52.71,64.17a3.53,3.53,0,0,0-3.53,3.52h0a3.53,3.53,0,1,0,7,0h0A3.52,3.52,0,0,0,52.71,64.17Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconBarInfo = (props) => {
    return <svg viewBox="0 0 92.02 101" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_10" data-name="Grupo 10">
                    <path id="Trazado_28" data-name="Trazado 28" className="cls-1"
                          d="M1.88,22.61A18.26,18.26,0,0,0,3,24.82Q11.8,37.64,20.62,50.41c.92,1.18,1.79,2.41,2.59,3.68A9.76,9.76,0,0,1,22,65.31c-3.18,4.27-6.5,8.41-9.72,12.64Q6.85,85.1,1.44,92.31A5.82,5.82,0,0,0,.18,97.6a4.76,4.76,0,0,0,5.92,3.2,5.08,5.08,0,0,0,.93-.39A10.22,10.22,0,0,0,8.91,99Q24.06,85.1,39.17,71.13a16.25,16.25,0,0,0,5.54-9.88,15.24,15.24,0,0,0-3.53-11.58,41.31,41.31,0,0,0-3.24-3.51Q24.29,32.41,10.61,18.66a11.5,11.5,0,0,0-1.23-1.07,4.4,4.4,0,0,0-7.14,1.78A5.16,5.16,0,0,0,1.88,22.61Z"/>
                </g>
                <g id="Grupo_11" data-name="Grupo 11">
                    <path id="Trazado_29" data-name="Trazado 29" className="cls-1"
                          d="M91.87,22.4a18.28,18.28,0,0,1-1.17,2.18Q81.55,37.17,72.33,49.69a45,45,0,0,0-2.68,3.61,9.77,9.77,0,0,0,.85,11.25c3.06,4.34,6.26,8.58,9.36,12.89q5.27,7.31,10.47,14.65a5.84,5.84,0,0,1,1.11,5.34,4.78,4.78,0,0,1-6,3,5.19,5.19,0,0,1-.93-.42,10.25,10.25,0,0,1-1.84-1.44Q67.92,84.29,53.21,69.92a16.21,16.21,0,0,1-5.26-10,15.23,15.23,0,0,1,3.86-11.47A39.92,39.92,0,0,1,55.14,45q14-13.38,28.11-26.76a10.79,10.79,0,0,1,1.26-1.07,4.38,4.38,0,0,1,6.19.39,4.45,4.45,0,0,1,.9,1.58A5.17,5.17,0,0,1,91.87,22.4Z"/>
                </g>
                <g id="Grupo_12" data-name="Grupo 12">
                    <path id="Trazado_30" data-name="Trazado 30"
                          d="M11,13.1a53.46,53.46,0,0,1,69.57-.47l-5,5.22a48.93,48.93,0,0,0-22.32-10A47.44,47.44,0,0,0,32.17,9.54,49,49,0,0,0,16,18.09Z"/>
                    <path id="Trazado_31" data-name="Trazado 31"
                          d="M20.9,23a36.9,36.9,0,0,1,12.34-7.84,33.11,33.11,0,0,1,12.82-2.37,31.54,31.54,0,0,1,8.32.95A34.31,34.31,0,0,1,70.76,23l-5,4.75a29.92,29.92,0,0,0-19.24-7.59,28.36,28.36,0,0,0-20.42,7.59Z"/>
                    <path id="Trazado_32" data-name="Trazado 32"
                          d="M35.61,37.2a15.4,15.4,0,0,1,8.32-4.75,14.34,14.34,0,0,1,7.59,1.19,14.11,14.11,0,0,1,4.75,3.56L61,32.45a19.41,19.41,0,0,0-15-7.12,20.7,20.7,0,0,0-6.65,1.19,21.38,21.38,0,0,0-8.79,5.93Z"/>
                </g>
            </g>
        </g>
    </svg>
}

export const XelIconBarStatus = (props) => {
    return <svg viewBox="0 0 78.61 95.83" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path id="Trazado_52" data-name="Trazado 52"
                      d="M37.63,32.94a15,15,0,1,0,15,15A15,15,0,0,0,37.63,32.94Zm0,26.21A11.23,11.23,0,1,1,48.86,47.92h0A11.25,11.25,0,0,1,37.63,59.15Z"/>
                <path id="Trazado_53" data-name="Trazado 53"
                      d="M77,79.32l-20-20a22.4,22.4,0,0,0-.82-24.09V5.61A5.63,5.63,0,0,0,50.54,0H5.61A5.63,5.63,0,0,0,0,5.61v84.6a5.64,5.64,0,0,0,5.61,5.62H50.54a5.64,5.64,0,0,0,5.61-5.62V74.38L69,87.26A5.61,5.61,0,0,0,77,79.32ZM37.63,66.64A18.71,18.71,0,1,1,56.34,47.93h0A18.74,18.74,0,0,1,37.63,66.64Zm.52-62.89-.52,1-.21.46C36.56,7,36.2,7.52,35.56,7.52h-15c-.63,0-1-.54-1.85-2.28l-.22-.46-.51-1ZM52.41,84.61H36.51a1.87,1.87,0,0,0,0,3.74H52.42v1.87a1.87,1.87,0,0,1-1.87,1.87H5.61a1.87,1.87,0,0,1-1.87-1.87V88.35H19.65a1.87,1.87,0,1,0,0-3.74H3.74v-79A1.87,1.87,0,0,1,5.61,3.74h8.22L15.2,6.45l.2.42c.91,1.82,2.15,4.36,5.21,4.36h15c3.06,0,4.3-2.52,5.21-4.36l.2-.42,1.37-2.71h8.22a1.87,1.87,0,0,1,1.87,1.87V31a22.46,22.46,0,1,0-3.38,36.24l3.38,3.38Zm21.9,0a1.9,1.9,0,0,1-2.65,0L52.11,65.06a22.87,22.87,0,0,0,2.65-2.65L74.31,82A1.88,1.88,0,0,1,74.31,84.61Z"/>
                <path id="Trazado_54" data-name="Trazado 54"
                      d="M28.07,84.6h0A1.87,1.87,0,1,0,30,86.47a1.87,1.87,0,0,0-1.88-1.87Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconBarConsume = (props) => {
    return <svg viewBox="0 0 95.98 95.98" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g id="Grupo_14" data-name="Grupo 14">
                    <path id="Trazado_34" data-name="Trazado 34"
                          d="M48.38,96a3,3,0,0,1-3-3,2.94,2.94,0,0,1,.88-2.13l11.38-11.4a3,3,0,0,1,4.24,4.25L56.49,89.1A42.2,42.2,0,0,0,90,48.34a3.17,3.17,0,0,1,3-3,3,3,0,0,1,3,3h0A48.18,48.18,0,0,1,48.4,96Z"/>
                    <path id="Trazado_35" data-name="Trazado 35"
                          d="M3,50.6H3a3,3,0,0,1-3-3A48.17,48.17,0,0,1,47.57,0a2.89,2.89,0,0,1,2.79,1.84,3,3,0,0,1-.65,3.28L38.34,16.5a3,3,0,0,1-4.24-4.24l5.39-5.4A42.2,42.2,0,0,0,6,47.62a3,3,0,0,1-3,3Z"/>
                </g>
                <path id="Trazado_36" data-name="Trazado 36"
                      d="M77.63,33.83a7,7,0,0,1-4.95-2L64.2,23.3a7,7,0,0,1,0-9.9h0a1,1,0,0,0,0-1.42,3,3,0,1,1,4.11-4.37l.13.14a7,7,0,0,1,0,9.89,1,1,0,0,0,0,1.41l8.49,8.48a.84.84,0,0,0,1.17.24.69.69,0,0,0,.24-.24,7,7,0,0,1,9.9,0,3,3,0,0,1-4.1,4.39L84,31.78a1,1,0,0,0-1.42,0,6.88,6.88,0,0,1-4.94,2Z"/>
                <path id="Trazado_37" data-name="Trazado 37"
                      d="M35.14,92a10.79,10.79,0,0,1-7.64-3.15L7.16,68.47a10.8,10.8,0,0,1,0-15.28l46-46a10.82,10.82,0,0,1,15.28,0L88.81,27.51a10.82,10.82,0,0,1,0,15.28l-46,46A10.76,10.76,0,0,1,35.14,92Zm-3.39-7.4a4.81,4.81,0,0,0,6.79,0l46-46a4.8,4.8,0,0,0,0-6.8L64.22,11.4a4.82,4.82,0,0,0-6.8,0l-46,46a4.82,4.82,0,0,0,0,6.8Z"/>
            </g>
        </g>
    </svg>
}

export const XelIconBarCode = (props) => {
    return <svg viewBox="0 0 63.74 96" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path id="Trazado_38" data-name="Trazado 38"
                      d="M39.37,57.38A1.88,1.88,0,1,0,37.5,55.5h0A1.88,1.88,0,0,0,39.37,57.38Z"/>
                <path id="Trazado_39" data-name="Trazado 39"
                      d="M31.87,57.38A1.88,1.88,0,1,0,30,55.5h0A1.88,1.88,0,0,0,31.87,57.38Z"/>
                <path id="Trazado_40" data-name="Trazado 40"
                      d="M31.87,84.75a1.88,1.88,0,1,0,1.88,1.87,1.87,1.87,0,0,0-1.88-1.87Z"/>
                <path id="Trazado_41" data-name="Trazado 41"
                      d="M24.37,57.38A1.88,1.88,0,1,0,22.5,55.5h0A1.88,1.88,0,0,0,24.37,57.38Z"/>
                <path id="Trazado_42" data-name="Trazado 42"
                      d="M5.62,0A5.64,5.64,0,0,0,0,5.62V90.37A5.63,5.63,0,0,0,5.62,96h52.5a5.63,5.63,0,0,0,5.62-5.63V5.62A5.63,5.63,0,0,0,58.12,0ZM60,77.26H3.77V15H60Zm-1.87,15H5.62a1.88,1.88,0,0,1-1.87-1.88V81H60v9.37a1.89,1.89,0,0,1-1.88,1.88ZM60,5.63v5.63H3.77V5.62A1.88,1.88,0,0,1,5.65,3.75H58.14A1.88,1.88,0,0,1,60,5.62Z"/>
                <path id="Trazado_43" data-name="Trazado 43"
                      d="M46.88,57.38a1.88,1.88,0,0,0,1.87-1.88V36.76a1.88,1.88,0,1,0-3.75,0h0V55.5a1.88,1.88,0,0,0,1.88,1.88Z"/>
                <path id="Trazado_44" data-name="Trazado 44"
                      d="M39.37,49.87A1.88,1.88,0,0,0,41.25,48V36.74a1.88,1.88,0,0,0-3.75,0V48a1.88,1.88,0,0,0,1.87,1.88Z"/>
                <path id="Trazado_45" data-name="Trazado 45"
                      d="M31.87,49.87A1.88,1.88,0,0,0,33.75,48V36.74a1.88,1.88,0,0,0-3.75,0V48a1.88,1.88,0,0,0,1.87,1.88Z"/>
                <path id="Trazado_46" data-name="Trazado 46"
                      d="M24.37,49.87A1.88,1.88,0,0,0,26.25,48V36.74a1.88,1.88,0,0,0-3.75,0V48a1.88,1.88,0,0,0,1.87,1.88Z"/>
                <path id="Trazado_47" data-name="Trazado 47"
                      d="M16.87,34.88A1.87,1.87,0,0,0,15,36.75V55.49a1.88,1.88,0,1,0,3.75,0V36.75a1.87,1.87,0,0,0-1.88-1.87Z"/>
                <path id="Trazado_48" data-name="Trazado 48"
                      d="M46.88,31.12H52.5v5.62a1.88,1.88,0,1,0,3.75,0h0v-7.5a1.88,1.88,0,0,0-1.88-1.87h-7.5a1.88,1.88,0,0,0,0,3.75Z"/>
                <path id="Trazado_49" data-name="Trazado 49"
                      d="M46.88,64.88h7.5A1.88,1.88,0,0,0,56.25,63V55.5a1.88,1.88,0,0,0-3.75,0v5.62H46.88a1.88,1.88,0,0,0,0,3.75Z"/>
                <path id="Trazado_50" data-name="Trazado 50"
                      d="M9.38,38.62a1.87,1.87,0,0,0,1.87-1.87V31.13h5.62a1.88,1.88,0,1,0,0-3.75H9.37A1.87,1.87,0,0,0,7.5,29.25v7.5a1.88,1.88,0,0,0,1.87,1.88Z"/>
                <path id="Trazado_51" data-name="Trazado 51"
                      d="M9.38,64.88h7.5a1.88,1.88,0,0,0,0-3.75H11.26V55.51a1.88,1.88,0,1,0-3.75,0V63a1.87,1.87,0,0,0,1.87,1.87Z"/>
            </g>
        </g>
    </svg>
}
export const XelIconBarHelp = (props) => {
    return <svg viewBox="0 0 101.34 101.34" version={'1.0'}  {...props} >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path id="Trazado_33" data-name="Trazado 33"
                      d="M50.67,0a50.64,50.64,0,1,0,.06,0Zm0,93.42A42.75,42.75,0,1,1,93.42,50.67h0A42.73,42.73,0,0,1,50.71,93.42ZM66.5,41.27A15.86,15.86,0,0,1,54.63,56.6V63a4,4,0,0,1-7.92,0v-9.9a4,4,0,0,1,3.95-4,7.92,7.92,0,1,0-7.91-7.93h0a4,4,0,1,1-7.92,0,15.84,15.84,0,1,1,31.67,0ZM55.62,74.92a4.95,4.95,0,1,1-4.95-5h0a5,5,0,0,1,4.95,4.94Z"/>
            </g>
        </g>
    </svg>
}
export const XelIconApp = (props) => {
    return <svg viewBox="0 0 67.71 68.85" version={'1.0'}  {...props} >
        <rect className="cls-1" x="29.84" y="4.59" width="2.3" height="2.3"/>
        <rect className="cls-1" x="34.43" y="4.59" width="2.3" height="2.3"/>
        <rect className="cls-1" x="32.13" y="61.97" width="2.3" height="2.3"/>
        <rect className="cls-1" x="6.89" y="33.28" width="2.3" height="2.3"/>
        <rect className="cls-1" x="43.61" y="20.66" width="2.3" height="2.3"/>
        <rect className="cls-1" x="42.46" y="25.25" width="19.51" height="2.3"/>
        <rect className="cls-1" x="42.46" y="29.84" width="17.21" height="2.3"/>
        <rect className="cls-1" x="49.34" y="20.66" width="12.62" height="2.3"/>
        <path className="cls-1"
              d="M66.56,16.07H55.08V4.59A4.59,4.59,0,0,0,50.49,0H16.07a4.59,4.59,0,0,0-4.59,4.59v24.1H1.15A1.14,1.14,0,0,0,0,29.84V48.2a1.14,1.14,0,0,0,1.15,1.14H11.48V64.26a4.58,4.58,0,0,0,4.59,4.59H50.49a4.58,4.58,0,0,0,4.59-4.59V39.49l2.77-2.77h8.71a1.15,1.15,0,0,0,1.15-1.15V17.21A1.15,1.15,0,0,0,66.56,16.07ZM16.07,2.3H50.49a2.29,2.29,0,0,1,2.3,2.29V9.18h-39V4.59A2.29,2.29,0,0,1,16.07,2.3ZM2.3,31H28.69V47.05H23a1.13,1.13,0,0,0-.81.34L19.51,50l-2.63-2.63a1.14,1.14,0,0,0-.81-.34H2.3ZM15.59,49.34l3.11,3.11a1.14,1.14,0,0,0,1.62,0l3.11-3.11h6.41A1.14,1.14,0,0,0,31,48.2V29.84a1.14,1.14,0,0,0-1.14-1.15H13.77V11.48h39v4.59H37.87a1.15,1.15,0,0,0-1.15,1.14V35.57a1.15,1.15,0,0,0,1.15,1.15H50l2.77,2.77V57.38h-39v-8Zm34.9,17.22H16.07a2.3,2.3,0,0,1-2.3-2.3V59.67h39v4.59A2.3,2.3,0,0,1,50.49,66.56ZM65.41,34.43h-8a1.14,1.14,0,0,0-.81.33l-2.64,2.63L51.3,34.76a1.14,1.14,0,0,0-.81-.33H39V18.36H65.41Z"/>
        <rect className="cls-1" x="5.74" y="37.87" width="19.51" height="2.3"/>
        <rect className="cls-1" x="5.74" y="42.46" width="17.21" height="2.3"/>
        <rect className="cls-1" x="11.48" y="33.28" width="13.77" height="2.3"/>
    </svg>
}
export const XelIconPagar = (props) => {
    return <svg viewBox="0 0 68.48 68.45" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M34.24,68.45a34.1,34.1,0,0,1-24.2-10,1,1,0,0,1,1.38-1.38A32.28,32.28,0,0,0,63.83,21.32a1,1,0,1,1,1.8-.78A34.22,34.22,0,0,1,34.24,68.45Z"/>
        <path className="cls-1"
              d="M3,46.52A1,1,0,0,1,2,45.87,34.24,34.24,0,0,1,58.45,10a1,1,0,0,1-1.38,1.38A32.28,32.28,0,0,0,3.88,45.2,1,1,0,0,1,3,46.52Z"/>
        <path className="cls-1" d="M57.76,11.7H51.27a1,1,0,0,1,0-2h5.51V4.23a1,1,0,0,1,2,0v6.49a1,1,0,0,1-1,1Z"/>
        <path className="cls-1"
              d="M10.73,65.21a1,1,0,0,1-1-1V57.75a1,1,0,0,1,1-1h6.48a1,1,0,1,1,0,2h-5.5v5.51a1,1,0,0,1-1,1Z"/>
        <path className="cls-1"
              d="M49.78,44.92H29a5.51,5.51,0,0,1-5.36-4.33L19.15,19.75H14.8a1,1,0,1,1,0-2h5.14a1,1,0,0,1,.95.77l4.64,21.62A3.55,3.55,0,0,0,29,43h20.8a1,1,0,0,1,0,2Z"/>
        <path className="cls-1"
              d="M45,38.19H23.89a1,1,0,0,1,0-1.95H45a2,2,0,0,0,1.94-1.67L48.42,25H21.05a1,1,0,0,1,0-1.95H49.56a1,1,0,0,1,1,1.12L48.86,34.87A3.9,3.9,0,0,1,45,38.19Z"/>
        <path className="cls-1" d="M44.7,54.59a4,4,0,1,1,4-4,4,4,0,0,1-4,4Zm0-6a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z"/>
        <path className="cls-1" d="M31.06,54.59a4,4,0,1,1,4-4,4,4,0,0,1-4,4Zm0-6a2,2,0,1,0,2,2,2.05,2.05,0,0,0-2-2Z"/>
    </svg>
}
export const XelIconCHIP = (props) => {
    return <svg viewBox="0 0 47.68 65.09" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M1,52.72a1,1,0,0,0,1-.95V29.89H2.3a1,1,0,0,0,1-1V19.1a1,1,0,0,0-1-1H1.91V3.61a1.7,1.7,0,0,1,1.7-1.7H27A1,1,0,0,0,27,0H3.61A3.61,3.61,0,0,0,0,3.61V19.1a1,1,0,0,0,1,.95h.39V28H1a1,1,0,0,0-.95.95V51.77a1,1,0,0,0,1,.95Z"/>
        <path className="cls-1"
              d="M46.72,20.05a1,1,0,0,0,1-.95V12.75a1,1,0,0,0-.28-.68L35.6.28A.93.93,0,0,0,34.93,0H31.5a1,1,0,1,0,0,1.91h3L45.77,13.14v5h-.39a.94.94,0,0,0-1,1v9.83a1,1,0,0,0,1,1h.39V61.48a1.72,1.72,0,0,1-1.71,1.71H3.61a1.71,1.71,0,0,1-1.7-1.71V56.25a1,1,0,1,0-1.91,0v5.23a3.61,3.61,0,0,0,3.61,3.61H44.06a3.62,3.62,0,0,0,3.62-3.61V28.93a1,1,0,0,0-1-.95h-.39V20.05Z"/>
        <path className="cls-1"
              d="M33.61,52.06a1,1,0,0,0,1-1V29.21a1,1,0,0,0-1-.95H6.7a1,1,0,0,0-1,.95V58.47a1,1,0,0,0,1,1H33.61a1,1,0,0,0,1-1v-3a1,1,0,0,0-1.91,0v2h-7.1V49.75h3.1a5.88,5.88,0,0,0,4-1.56v2.92a1,1,0,0,0,1,1Zm-1-12.56a5.89,5.89,0,0,0-4-1.57h-3.1V30.16h7.1Zm-16-1.57V30.16h7v7.77Zm-1.91-7.77v7.77h-3.1a5.89,5.89,0,0,0-4,1.57V30.16Zm-7.1,18a5.88,5.88,0,0,0,4,1.56h3.1v7.77H7.65Zm16,9.33h-7V49.75h7Zm5-9.67h-17a4,4,0,0,1,0-8h17a4,4,0,0,1,0,8Z"/>
        <path className="cls-1" d="M6.7,21.68a1,1,0,0,0,1-.95V6.6a1,1,0,0,0-1.91,0V20.73a1,1,0,0,0,1,.95Z"/>
        <path className="cls-1" d="M21.8,15.21a1,1,0,0,0,1-1V6.6a1,1,0,0,0-1.91,0v7.66a1,1,0,0,0,.95,1Z"/>
        <path className="cls-1" d="M20.85,20.73a1,1,0,0,0,1.91,0V18.64a1,1,0,0,0-1.91,0Z"/>
        <path className="cls-1" d="M12.65,18.18V6.6a1,1,0,1,0-1.9,0V18.18a1,1,0,1,0,1.9,0Z"/>
        <path className="cls-1" d="M27.72,18.18V6.6a1,1,0,0,0-1.91,0V18.18a1,1,0,1,0,1.91,0Z"/>
        <path className="cls-1" d="M17.65,20.73V6.6a.95.95,0,1,0-1.9,0V20.73a.95.95,0,0,0,1.9,0Z"/>
    </svg>
}

export const XelIconMexLogo = (props) => {
    return <svg viewBox="0 0 96.38 63.25" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M94.72,37.31H86.41a1.89,1.89,0,0,0-1.29.85l-2.4,3.67a5.06,5.06,0,0,0-.56,1.55c-.41,2.1-2.49,7.51-13.2,7.51-13.33,0-12.14-19.78-11.73-24.1a2.44,2.44,0,0,0-.4-1.48L47.85,13c-.27-.37-.87-1-1.33-1h-5c-.46,0-1.06,0-1.35-.34l-5.61-7a1.93,1.93,0,0,0-1.35-.59H26.58c-.47,0-1.29.58-1.29,1V6.86c0,.46.08.45-.38.45h-5A2.57,2.57,0,0,1,18.44,7l-7-5.53A2.66,2.66,0,0,0,10,1H1.64A.61.61,0,0,0,1,1.82l4.55,17C5.69,19.27,5.42,20,5,20H1.64c-.46,0-.57-.05-.24.28l5.47,5.31a6.77,6.77,0,0,1,1,1.14L17,41.89a.91.91,0,0,0,1.17.34L20,41.31a.91.91,0,0,0,.43-1.14l-11-27c-.18-.42.05-1.08.51-1.08h1.66c.47,0,1,.62,1.3,1L28.6,36.76a7.11,7.11,0,0,1,.7,1.57l2.87,10.08a2.74,2.74,0,0,0,.9,1.29L48.33,60.4a2.26,2.26,0,0,0,1.5.31l14.78-3.1a2.46,2.46,0,0,1,1.53.27l7.09,4.24a1.12,1.12,0,0,0,1.3-.17l2.16-2.05a2.19,2.19,0,0,1,1.41-.48h5a.54.54,0,0,0,.45-.85l-.91-1.88a1.3,1.3,0,0,1,.21-1.36L93.3,44.85a3.45,3.45,0,0,0,.79-1.39l1.26-5.19a.74.74,0,0,0-.63-1Z"/>
    </svg>
}

export const XelIconFacebookMain = (props) => {
    return <svg viewBox="0 0 12.74 25.48" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M8,8.76V5.57A1.6,1.6,0,0,1,9.56,4h1.59V0H8A4.77,4.77,0,0,0,3.19,4.78v4H0v4H3.19V25.48H8V12.74h3.19l1.59-4Z"/>
    </svg>
}
export const XelIconTwitterMain = (props) => {
    return <svg viewBox="0 0 31.35 25.48" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M9.86,25.48c11.83,0,18.3-9.8,18.3-18.3,0-.28,0-.56,0-.83A13.16,13.16,0,0,0,31.35,3a13,13,0,0,1-3.69,1A6.47,6.47,0,0,0,30.49.47,12.81,12.81,0,0,1,26.4,2a6.43,6.43,0,0,0-11.12,4.4,6.43,6.43,0,0,0,.16,1.47A18.28,18.28,0,0,1,2.18,1.18a6.45,6.45,0,0,0,2,8.59A6.53,6.53,0,0,1,1.26,9V9a6.44,6.44,0,0,0,5.16,6.31,6.34,6.34,0,0,1-1.7.23,6.13,6.13,0,0,1-1.21-.12,6.44,6.44,0,0,0,6,4.47,12.92,12.92,0,0,1-8,2.75A13,13,0,0,1,0,22.59a18.18,18.18,0,0,0,9.86,2.89"/>
    </svg>
}

export const XelIconMessengerMain = (props) => {
    return <svg viewBox="0 0 25.29 25.48" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M12.64,0C5.66,0,0,5.28,0,11.8a11.48,11.48,0,0,0,4.66,9.14v4.54L8.9,23.07a13.52,13.52,0,0,0,3.74.52c7,0,12.65-5.28,12.65-11.79S19.63,0,12.64,0Zm1.28,15.79-3.24-3.41L4.47,15.79l6.82-7.22,3.28,3.34L20.7,8.57Z"/>
    </svg>
}

export const XelIconInstagramMain = (props) => {
    return <svg viewBox="0 0 25.48 25.48" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M25.41,7.49a9.45,9.45,0,0,0-.59-3.09,6.18,6.18,0,0,0-1.47-2.26A6.27,6.27,0,0,0,21.09.67,9.45,9.45,0,0,0,18,.08C16.64,0,16.2,0,12.74,0S8.85,0,7.49.07A9.17,9.17,0,0,0,4.4.67,6.18,6.18,0,0,0,2.14,2.14,6.27,6.27,0,0,0,.67,4.4,9.4,9.4,0,0,0,.08,7.49C0,8.85,0,9.28,0,12.74S0,16.64.07,18a9.17,9.17,0,0,0,.6,3.09,6.27,6.27,0,0,0,1.47,2.26A6.4,6.4,0,0,0,4.4,24.82a9.4,9.4,0,0,0,3.09.59c1.36.06,1.79.07,5.25.07s3.89,0,5.25-.07a9.4,9.4,0,0,0,3.09-.59,6.55,6.55,0,0,0,3.73-3.73A9.45,9.45,0,0,0,25.4,18c.06-1.36.08-1.8.08-5.26s0-3.89-.07-5.25ZM23.12,17.9a7.22,7.22,0,0,1-.44,2.36,4.24,4.24,0,0,1-2.42,2.42,7.22,7.22,0,0,1-2.36.44c-1.35.06-1.75.07-5.15.07s-3.81,0-5.15-.07a7.16,7.16,0,0,1-2.36-.44,3.89,3.89,0,0,1-1.47-.95,4.05,4.05,0,0,1-1-1.46,7.3,7.3,0,0,1-.44-2.37c-.06-1.34-.07-1.75-.07-5.15s0-3.8.07-5.14a7.24,7.24,0,0,1,.44-2.37,4,4,0,0,1,1-1.46,3.93,3.93,0,0,1,1.46-.95,6.79,6.79,0,0,1,2.37-.44c1.34-.06,1.74-.08,5.14-.08s3.81,0,5.15.08a6.73,6.73,0,0,1,2.37.44,3.93,3.93,0,0,1,1.46.95,3.93,3.93,0,0,1,.95,1.46,7.3,7.3,0,0,1,.44,2.37c.06,1.34.07,1.74.07,5.14s0,3.8-.07,5.15Z"/>
        <path className="cls-1"
              d="M12.74,6.2a6.55,6.55,0,1,0,6.55,6.54A6.54,6.54,0,0,0,12.74,6.2Zm0,10.79A4.25,4.25,0,1,1,17,12.74,4.24,4.24,0,0,1,12.74,17Z"/>
        <path className="cls-1" d="M21.08,5.94a1.53,1.53,0,1,1-1.53-1.53,1.54,1.54,0,0,1,1.53,1.53Z"/>
    </svg>
}

export const XelIconYouTubeMain = (props) => {
    return <svg viewBox="0 0 36.4 25.48" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M35.65,4A4.56,4.56,0,0,0,32.44.78C29.59,0,18.2,0,18.2,0S6.81,0,4,.75A4.66,4.66,0,0,0,.75,4C0,6.83,0,12.74,0,12.74s0,5.94.75,8.75A4.56,4.56,0,0,0,4,24.7c2.88.78,14.24.78,14.24.78s11.39,0,14.24-.75a4.56,4.56,0,0,0,3.21-3.21c.75-2.84.75-8.75.75-8.75s0-5.94-.75-8.78ZM14.57,18.2V7.28L24,12.74Z"/>
    </svg>
}

export const XelIconTikTokMain = (props) => {
    return <svg viewBox="0 0 23.89 27.19" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M23.88,6.81a6.89,6.89,0,0,1-4.14-1.38,6.92,6.92,0,0,1-2.61-4.14A7.83,7.83,0,0,1,17,0H12.56V18.78A4,4,0,0,1,9.8,22.59a3.93,3.93,0,0,1-1.5.2,4.09,4.09,0,0,1-1.83-.56,4,4,0,0,1,2.06-7.47A4.12,4.12,0,0,1,9.8,15v-4.5a7.8,7.8,0,0,0-1.28-.1,8.51,8.51,0,0,0-6.4,2.86,8.36,8.36,0,0,0,.37,11.51,10.69,10.69,0,0,0,.81.7,8.51,8.51,0,0,0,5.22,1.76A9.07,9.07,0,0,0,9.8,27.1a8.55,8.55,0,0,0,4.74-2.37A8.31,8.31,0,0,0,17,18.81l0-9.92a11.26,11.26,0,0,0,2.49,1.45,11.18,11.18,0,0,0,4.39.89V6.81Z"/>
    </svg>
}
