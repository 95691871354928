import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import Cookie from 'js-cookie';
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    text: {
        fontSize: '10pt',
        color: '#707070'
    },
    container: {
        marginTop: '5%'
    }
});
export default function UpdatedAt() {
    const dateUpdated = Cookie.get('saved_date');
    const style = useStyles();
    useEffect(() => {

    });
    return <Grid container className={style.container}>
        <Grid item xs={12}>
            <Typography className={style.text} align={'center'}>
                Ultima actualización {dateUpdated}
            </Typography>
        </Grid>
    </Grid>
}