const loggedReducer = (state = {
    logged: false,
    token: '',
    flag: true,
    s_token: ''
}, action) => {
    switch (action.type) {
        case "SIGN_IN":
            return (state = action.payload);
        case "LOG_OUT":
            return (state = {
                logged: false,
                token: '',
                flag: true
            });
        default:
            return state;
    }
};

export default loggedReducer;