import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';


import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';
import Typography from "@material-ui/core/Typography";
import BottomBar from "../../ui/BottomBar";
import {XelIconLocation} from "../../assets/home/svg";

const useStyle = makeStyles({
        contenedor: {
            backgroundColor: '#FEFEFE',
            height: '100%',
            position: 'relative',
            paddingTop: 80,
            marginBottom: 70,
            paddingBottom: 70,
            overflowX: 'scroll'
        },
        logo: {
            maxHeight: 67,
            height: '100%'
        },
        logo_container: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '5%'
        },
        border_line: {
            backgroundColor: XEL_COLORS.primary,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        border_text: {
            color: '#FEFEFE',
            fontSize: 15,
            fontWeight: XEL_FONT.bold_weight
        },
        sub_container: {
            //    padding: '1%',
            marginBottom: 70
        },
        date_text: {
            fontSize: '15pt',
            color: '#707070'
        },
        ins_text: {
            fontSize: '10pt',
            color: '#707070'
        }
        ,
        image_container: {
            display: 'flex',
            justifyContent: 'center'
        },
        info_text: {
            color: '#707070',
            fontSize: '10pt'
        },
        title_dir_container: {
            borderTop: `1px solid #000000`,
            borderBottom: `1px solid #000000`,
            marginTop: '2%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        map_container: {

            width: '100%',
            display: "flex",
            justifyContent: 'center',

        },
        map: {
            height: '550pt',
            width: '95%'
        },
        icon_location: {
            height: '27pt',
            fill: XEL_COLORS.secondary,
            stroke: 'none',
            paddingRight: '3%'
        }
    }
)
export default function Reference() {

    const profile = useSelector(state => state.profiledetail);
    const user = useSelector(state => state.profile);
    const style = useStyle();
    useEffect(() => {
        let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',};
        let fecha = new Date();
        console.log(
            fecha.toLocaleDateString("es-MX", options)
        );
    }, [])
    return <Grid container className={style.contenedor}>
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={3}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12} className={style.border_line}>
                    <Typography className={style.border_text}>
                        Mi referencia
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={style.date_text} align={'center'}>
                        Referencia de pago
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={style.ins_text} align={'center'}>
                        Indícale al cajero que vas a realizar un pago referenciado de PayNet
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={style.date_text} align={'center'}>
                        <img src={`https://api.openpay.mx/barcode/935000${user.msisdn}`} alt="Mi código"/>
                        <br/>
                        935000{user.msisdn}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={style.info_text} align={'center'}>
                        <strong>{
                            profile.date_cut ===0 ?" Presenta este código para pagar tu recarga en Grupo Wallmart, Seven Eleven, Farmacias del Ahorro, Sams Club, entre otros.":"Presenta este código para pagar en Grupo Walmart, Seven Eleven, Farmacias del Ahorro, Sams Club, entre otros."
                        } </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={style.title_dir_container}>
                    <XelIconLocation className={style.icon_location}/>
                    <Typography className={style.info_text} align={'center'}>
                        <a href="https://www.paynet.com.mx/mapa-tiendas/">Localiza el punto de pago más cercano</a>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={style.info_text} align={'center'}>
                        ¿Quieres pagar en otras tiendas?
                    </Typography>
                    <Typography className={style.info_text} align={'center'}>
                        visitanos en: <a href="https://www.openpay.mx/tiendas/"
                                         target="_blank">www.openpay.mx/tiendas</a>
                    </Typography>
                </Grid>
            </Grid>
            <BottomBar/>
        </Grid>
    </Grid>
}