import {API} from "../../constants";
import Cookie from "js-cookie";
import Axios from "axios";

export default async function LastChargesService(msisdn, token) {
    const login = new Promise((resolve, reject) => {
        let data = {
            token,
        };
        const axiosXel = Axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "https://api.inxel.mx/",
            //   timeout: 1000,
            headers: {'auth-token': token}
        });
        axiosXel.get(`/clients/dn/${msisdn}/last.charges.get`).then(res => {
            resolve({
                status: 'OK',
                data: res.data
            });
        }).catch(err => {
            console.log(err);
            reject({
                status: 'ERROR',
                description: err.data
            });
        })

    });

    const result = login.then(response => {
        return response;
    }).catch(error => {
        return error;
    });

    return await result;
}
