import React from "react";
import {Grid, Typography} from "@material-ui/core";
import './styles.css';

export default function LoadingText() {
    return <Grid container>
        <Grid item xs={12}>
            <Typography style={{fontSize: 11}} align={'center'}>
                Cargando<span className={'loading-label-component'}/>
            </Typography>
        </Grid>
    </Grid>
}