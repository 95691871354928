import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import NavBar from "../../ui/NavBar";
import BottomBar from "../../ui/BottomBar";

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%'
    },
    iframe: {
        width: '100%',
        height: 'calc(100vh - 36px)',
    }
})

export default function Chat() {
    const styles = useStyles();

    return <Grid container className={styles.root}>
        <Grid item xs={12} className={styles.root}>
            <NavBar/>
            <iframe src="https://crm.inxel.mobi/online/cliente" className={styles.iframe} frameBorder="0"
                    title={'CHAT PWA'}/>
            <BottomBar/>
        </Grid>
    </Grid>
}