import React from "react";
import Grid from "@material-ui/core/Grid";
import Logo from '../../assets/inxel/logos/logo-inxel-blanco.png'
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS} from "../../constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fondo from "../../assets/images/fondo.svg";

const usestyles = makeStyles({

    contenedor: {
        backgroundColor: XEL_COLORS.primary,
        height:'100%',
        display:'flex',
        backgroundImage: `url(${Fondo})`,
        backgroundSize: 'cover'
    },
    img:{
        maxWidth:175,
        width:'100%',
        maxHeight:105,
        marginBottom:5
    },
    _container:{
        display:'flex',
        justifyContent:'center',
        height:'fit-content',
        flexDirection:'column',
        alignItems:'center'
    },
})

const Loading = () => {
    const style = usestyles();
    return <Grid container className={style.contenedor} alignItems={'center'} >
        <Grid item xs={12} className={style._container}>
            <img src={Logo} alt={'inXel'} className={style.img}/>
            <CircularProgress color={'secondary'} />
        </Grid>
    </Grid>
}

export default Loading;