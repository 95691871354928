import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import BottomBar from "../../ui/BottomBar";
import Cookie from 'js-cookie';
import {
    XelIconApp,
    XelIconCalendar,
    XelIconCalendarPoint,
    XelIconCard,
    XelIconPagar,
    XelIconPaySign, XelIconTrashCan
} from "../../assets/home/svg";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Details from "../../components/Details";
import Axios from "axios";
import LoadingText from "../../components/Loading";
import Prepago from "./Prepago";
//import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';

const useStyle = makeStyles({
        contenedor: {
            backgroundColor: '#FEFEFE',
            height: '100%',
            position: 'relative',
            paddingTop: 80,

        },
        sub_container: {
            marginBottom: 70
        },
        logo: {
            maxHeight: 67,
            height: '100%'
        },
        logo_container: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '5%'
        },
        border_line: {
            backgroundColor: XEL_COLORS.primary,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        border_line_card: {
            backgroundColor: XEL_COLORS.secondary,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10%'
        }
        ,
        border_text: {
            color: '#FEFEFE',
            fontSize: 15,
            fontWeight: XEL_FONT.bold_weight
        },
        list_container: {
            marginTop: 0,
            paddingLeft: '5%'
        },
        item_text_title: {
            color: "#707070",

            fontSize: '15pt',

        },
        item_text_value: {
            color: "#707070",
            fontWeight: XEL_FONT.bold_weight,
            fontSize: '11pt',
            letterSpacing: 4,
            paddingLeft: '2%'
        },

        list_item: {
            borderBottom: '1px solid #000000',
            display: 'flex',
            justifyContent: 'center'
        },
        option: {
            display: 'flex', justifyContent: "space-around",
            borderBottom: '1px solid #000000',
            cursor: 'pointer',
            alignItems: 'center',
            padding: '5%'
        },
        option_icon: {
            stroke: 'none',
            fill: XEL_COLORS.primary,
            height: '32pt',
            paddingRight: '30px'
            //justifySelf:'flex-start'
        },
        option_icon_del: {
            stroke: 'none',
            height: '20pt',
            fill: XEL_COLORS.primary
        },
        option_icon_card: {
            stroke: 'none',
            height: '30px',
            fill: XEL_COLORS.secondary
        },
        next: {
            marginLeft: '5%',
            marginRight: 0
        },
        icon_container: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        detail_container: {
            borderBottom: '1px solid #000000',
            padding: '10px'
        },
        icon_button: {
            height: '35pt',
            width: '35pt',
            display: 'flex',
            justifyContent: 'center'
        },
        card_container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
        },
        trash_can_icon: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        add_option: {
            color: '#707070',
            fontSize: '15pt'
        },
        add_option_container: {
            borderBottom: '1px solid #000000',
            padding: '10px',
            cursor: 'pointer'
        },
        button_container: {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '15px'
        },
        button: {
            backgroundColor: XEL_COLORS.primary,
            color: '#FEFEFE',
            borderRadius: '8pt',
            fontSize: '8pt',
            width: '80pt',
            height: '40pt',
        }
    }
)
const Detail = ({title, body, Icon}) => {
    const history = useHistory();
    const style = useStyle();
    return <Grid container alignItems={'center'} className={style.detail_container}>
        <Grid item xs={4} className={style.icon_container}>
            <Icon className={style.option_icon}/>
        </Grid>
        <Grid item xs={5}>
            <Typography className={style.item_text_title} align={'center'}>
                {title}
            </Typography>
            <Typography className={style.item_text_value} align={'center'}>
                {body}
            </Typography>
        </Grid>
    </Grid>
}

const Card = ({id, number, error}) => {
    const history = useHistory();
    const style = useStyle();
    const session = useSelector(state => state.login);
    const handleDel = () => {
        if (session.logged)
            if (window.confirm('¿Estás seguro de borrar la tarjeta?')) {
                let body = {
                    token: session.token,
                    id_card: id,
                    mode: 'prod'
                }
                fetch('https://inxel.mx/api/xel/v1/pwa.delete.card.json', {
                    method: "POST",
                    body: JSON.stringify(body)
                }).then(res => res.json())
                    .then(res => {
                        if (res.error) {
                            error({
                                open: true,
                                severity: 'error',
                                msg: 'Error al intentar borrar, verifique su conexion'
                            });
                            return;
                        }
                        window.location.reload();
                    }).catch(e => {
                        error({
                            open: true,
                            severity: 'error',
                            msg: 'Error al intentar borrar, verifique su conexion'
                        })
                    }
                )
            }

    }
    return <Grid container alignItems={'center'} className={style.detail_container}>
        <Grid item xs={3} className={style.trash_can_icon}>
            <IconButton aria-label="delete" className={style.icon_button} onClick={handleDel}>
                <XelIconTrashCan className={style.option_icon_del}/>
            </IconButton>
        </Grid>
        <Grid item xs={8} className={style.card_container}>
            <XelIconCard className={style.option_icon_card}
            />
            <Typography className={style.item_text_value} align={'center'}>
                {number}
            </Typography>
        </Grid>

    </Grid>
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesDrop = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        padding: 5
    },
    container: {
        maxWidth: 600,
        width: '100%',
        maxHeight: 500,
        borderRadius: 10,
        backgroundColor: '#FEFEFE',
        padding: '2%'
    },
    card: {
        fontSize: 20,
        color: '#000000'
    },
    button: {
        border: `1px solid ${XEL_COLORS.primary}`,
        padding: 5,
        margin: 3
    }
}));

export default function PayOnline() {
    const styledrop = useStylesDrop();
    const history = useHistory();
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const style = useStyle();
    const [cards, setCards] = useState([]);
    const [gettingCards, setGettingCards] = useState(false);
    const [openDet, setOpenDet] = useState(false);
    const [open, setOpen] = React.useState({
        open: false,
        severity: 'success',
        msg: ''
    });
    const [selectedPlanP, setSelectedPlanP] = useState('');
    const [openPayNow, setOpenPay] = useState(false);
    const profile = useSelector(state => state.profile);
    useEffect(() => {
        if (session.logged) {
            if (parseInt(date_cut) !== 0) {
                let body = {
                    token: session.token
                }
                setGettingCards(true);
                Axios.get('https://api.inxel.mx/clients/pwa.cards', {
                    headers: {
                        'auth-token': session.token
                    }
                }).then(res => {
                    console.log('cards', res)
                    setCards(res.data);
                    setGettingCards(false);
                }).catch(err => {
                    setOpen({
                        open: true,
                        severity: 'error',
                        msg: 'Hubo un error de conexión al obtener las tarjetas, intenta de nuevo'
                    })
                });
                /*
                fetch('https://inxel.mx/api/xel/v1/pwa.get.cards.json', {
                    method: 'POST',
                    body: JSON.stringify(body)
                }).then(res => res.json())
                    .then(res => {

                    }).catch(e => {
                    setOpen({
                        open: true,
                        severity: 'error',
                        msg: 'Hubo un error de conexión, intenta de nuevo'
                    })
                });*/
            }
        }

    }, []);
    const details = useSelector(state => state.profiledetail);
    const [planes, setPlanes] = useState({
        loading: true,
        catalog: []
    });
    const {
        name_product,
        pay,
        date_cut,
        date_final,
        charges
    } = details;

    const date = new Date();
    const actualYear = date.getFullYear();
    const actualMonth = date.getMonth();
    const actualDay = date.getDate();
    let fechaCorte = '';
    let fechaFinal = '';
    if (parseInt(date_cut) < parseInt(date_final) && parseInt(date_final) > actualDay) {

        fechaFinal = `${date_final}/${actualMonth + 1}/${actualYear}`;
    } else if (parseInt(date_cut) < parseInt(date_final) && parseInt(date_final) < actualDay) {
        fechaFinal = `${date_final}/${actualMonth + 2}/${actualYear}`;
        if (actualMonth + 2 === 13) {
            fechaFinal = `${date_final}/01/${actualYear + 1}`;
        }

    } else if (parseInt(date_cut) > parseInt(date_final) && parseInt(date_final) < actualDay) {

        fechaFinal = `${date_final}/${actualMonth + 2}/${actualYear}`;
        if (actualMonth + 2 === 13) {
            fechaFinal = `${date_final}/01/${actualYear + 1}`;
        }
    }

    const [modality, setModality] = useState('PRE');
    useEffect(() => {
        if (parseInt(date_cut) === 0) {
            const prepagos = Cookie.get('prepagos');
            console.log(prepagos)
            if (prepagos && prepagos !== '') {
                const pre = JSON.parse(prepagos)
                setPlanes({
                    loading: false,
                    catalog: pre
                });
                if (pre.length > 1 || pre.length === 1) {
                    setSelectedPlanP(pre[0].id_product);
                    dispatch(Actions.SET_PRE_BUYDET(pre[0].id_product, pre[0].price, pre[0].name));
                }
            } else {
                Axios.get('https://api.inxel.mx/catalog/' + profile.msisdn + '/planes').then(res => {
                    const plans = res.data;
                    let arr = [];
                    for (let i = 0; i < plans.length; i++) {
                        const plan = plans[i];
                        if (plan.modality.toLowerCase() === 'pre') {
                            setModality('PRE')
                        } else {
                            setModality('PREFI');
                        }
                        arr.push(plan)
                    }
                    if (arr.length > 1 || arr.length === 1) {
                        setSelectedPlanP(arr[0].id_product);
                        dispatch(Actions.SET_PRE_BUYDET(arr[0].id_product, arr[0].price, arr[0].name));
                    }
                    Cookie.set('prepagos', arr, {expires: 3})
                    setPlanes({
                        loading: false,
                        catalog: arr
                    })
                }).catch(err => {
                    setPlanes({
                        loading: false,
                        catalog: []
                    })
                    console.log(err);
                });
            }

        }
    }, [])

    const handlePayNowCard = () => {
        history.push('/pagar-ahora/mixel');
    }
    const handleAddCard = () => {
        history.push('/tarjeta');
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen({
            ...open,
            open: false,
        });
    };
    const handlePayNow = () => {
        if (cards.length === 0) {
            setOpen({
                open: true,
                severity: 'warning',
                msg: 'No tienes tarjetas guardadas'
            });
            return;
        }
        setOpenPay(true);
    }
    const handleCloseDrop = () => {
        setOpenPay(false);
    }
    const handlePayWithCard = () => {

    }
    const handleOpen = () => {
        setOpenDet(true);
    }
    const handleCloseDet = () => {
        setOpenDet(false)
    }
    return <Grid container className={style.contenedor}>
        {
            parseInt(date_cut) !== 0 ? <Details open={openDet} setOpen={handleCloseDet}/> : ""
        }
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={3}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12} className={style.border_line}>
                    <Typography className={style.border_text}>
                        {
                            parseInt(date_cut) !== 0 ? "Pago en línea" : "Recarga en línea"
                        }
                    </Typography>
                </Grid>
                {
                    parseInt(date_cut) !== 0 ? <> <Grid item xs={12} className={style.list_container}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Detail title={"Total a pagar"} body={`$${pay}.00`} Icon={XelIconPaySign}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Detail title={"Mes de facturación"}
                                        body={`${meses[actualMonth]}`}
                                        Icon={XelIconCalendar}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Detail title={"Pagar antes de"} body={fechaFinal} Icon={XelIconCalendarPoint}/>
                            </Grid>
                        </Grid>
                    </Grid>

                        <Grid item xs={12} className={style.border_line_card}>
                            <Typography className={style.border_text}>
                                Domiciliar tarjeta
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={style.list_container}>
                            <Grid container>
                                {
                                    cards.map(card => <Grid item xs={12}>
                                        <Card number={card.card_number} id={card.id} error={setOpen}/>
                                    </Grid>)
                                }
                                {
                                    gettingCards ?
                                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                            <CircularProgress style={{fontSize: 20}}/>
                                        </Grid> : ''
                                }

                                <Grid item xs={12} className={style.add_option_container}>
                                    <Typography className={style.add_option} align={'center'} onClick={handleAddCard}>
                                        Agregar tarjeta +
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={style.button_container}>
                                    <Button className={style.button} onClick={handlePayNowCard}>
                                        Pagar ahora con otra tarjeta
                                    </Button>
                                </Grid>
                                <Grid item xs={12} className={style.button_container} style={{marginTop: '5.0%'}}>
                                    <Typography style={{color: 'blue', fontSize: 12}} onClick={handleOpen}>
                                        ¿Cuándo me cobran?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid></> : <><Grid item xs={12}>
                        <Typography style={{fontSize: 12, color: "#707070", marginTop: 10, marginBottom: 15}}
                                    align={'center'}>
                            Realiza la recarga que más se adapte a ti y sigue disfrutando de todos nuestros beneficios.
                        </Typography>
                    </Grid>
                        <Grid container spacing={2}>
                            {
                                planes.loading ?
                                    <Grid item xs={12}>
                                        <LoadingText/>
                                    </Grid> : planes.catalog.length > 0 ?
                                    <Grid container spacing={3} justify={'center'}>
                                        {
                                            planes.catalog.map(plan => <Grid key={plan.id_product} item xs={12}>
                                                <Prepago
                                                    onClick={() => {
                                                        setSelectedPlanP(plan.id_product);
                                                        dispatch(Actions.SET_PRE_BUYDET(plan.id_product, plan.price, plan.name));
                                                    }
                                                    }
                                                    selected={selectedPlanP.toString() === plan.id_product.toString()}
                                                    data={plan}/>
                                            </Grid>)
                                        }
                                        {
                                            modality === 'PRE' ? <Grid item xs={10}>
                                                <Typography align={'center'} style={{fontSize: 11}}>
                                                    * Política de uso justo: Superando los primeros 20GB, cuentas con
                                                    20GB
                                                    adicionales para
                                                    seguir
                                                    navegando con velocidad limitada a 512 Kbps.
                                                </Typography>
                                            </Grid> : ""
                                        }
                                    </Grid> : <Grid item xs={12}>
                                        <Typography align={'center'}>
                                            Oh!, parace que no hay planes disponibles contactanos para solucionarlo.
                                        </Typography>
                                    </Grid>

                            }
                        </Grid>
                    </>
                }
            </Grid>
            <Snackbar open={open.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={open.severity}>
                    {open.msg}
                </Alert>
            </Snackbar>
            {
                /*     <Backdrop className={styledrop.backdrop} open={openPayNow}>
                <Grid container className={styledrop.container} justify={'center'} alignItems={'center'}
                      direction={'column'}>
                    <Grid container xs={10}>
                        <Typography align={'center'} variant={'body2'} className={style.item_text_title}
                                    style={{padding: 2}}>
                            Selecciona una de tus tarjetas
                        </Typography>
                    </Grid>
                    {
                        cards.map(card => <Grid item xs={6} className={style.card_container}>
                                <Button className={styledrop.button}>
                                    <XelIconCard className={style.option_icon_card}
                                    />
                                    <Typography className={styledrop.card} align={'center'}>
                                        {card.card_number}
                                    </Typography>
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </Backdrop>*/
            }
            <BottomBar/>
        </Grid>
    </Grid>
}