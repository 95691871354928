import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import Rutas from "../../routes";
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../ui/Loading";
import {BrowserRouter as Router, useHistory, useLocation} from "react-router-dom";
import profileService from "../../services/GetProfileService";
import * as Actions from '../actions';
import profileDetailService from "../../services/GetProfileDetailService";
import recordsService from "../../services/GetRecordsService";
import Cookie from 'js-cookie';
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PwaInstall from "../../ui/PwaInstall";
import LastChargesService from "../../services/GetLastCharges";
import {sequenceEqual} from "rxjs/operators";
import loggedReducer from "../reducers/LoginReducer";

const useStyle = makeStyles({
    contenedor: {
        height: '100%',
        maxWidth: 450
    },
    backdrop: {
        zIndex: 1200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: 200,
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10
    },
    text: {
        fontSize: '13pt',
    }
});
const Contenedor = () => {
    const style = useStyle();
    const session = useSelector(state => state.login);
    const details = useSelector(state => state.profiledetail)
    const dispatch = useDispatch();
    const loading = useSelector(state => state.superloading);
    const profile = useSelector(state => state.profile);
    const history = useHistory();
    const isSaved = Cookie.get('saved_data');
    const messages = useSelector(state => state.messages);
    const location = useLocation();
    useEffect(() => {
        document.getElementById('super').scrollTo(0, 0);
        //window.scrollTo(0, 0);
    }, [location])
    useEffect(() => {
        dispatch(Actions.LOADING());
        if (isSaved) {
            if (isSaved === 'OK') {
                const profile = Cookie.get('saved_profile');

                const profile_parsed = JSON.parse(profile);
                dispatch(Actions.SET_DATA(profile_parsed.name, profile_parsed.second_name, profile_parsed.msisdn));
                const records = Cookie.get('saved_records');
                const details = Cookie.get('saved_profile_details');

                dispatch(Actions.SET_DATA_DETAILS(JSON.parse(details)));
                dispatch(Actions.NOT_LOADING());
                if (records)
                    dispatch(Actions.SET_RECORDS_DATA(JSON.parse(records)));

                if (!session.logged) {
                    const forToken = Cookie.get('xel_tk_client');
                    const tkToken = Cookie.get('xel_usr_tk');
                    if (forToken && tkToken)
                        if (forToken !== '' && tkToken !== '') {
                            dispatch(Actions.LOG_IN(forToken, tkToken, !session.flag));
                        }else{
                            dispatch(Actions.LOG_OUT());
                      //      window.reload();
                        }
                }
            }
        } else if (session.logged) {
            load();
        } else {
            const forToken = Cookie.get('xel_tk_client');
            const tkToken = Cookie.get('xel_usr_tk');
            if (forToken && tkToken)
                if (forToken !== '' && tkToken !== '') {
                    dispatch(Actions.LOG_IN(forToken, tkToken, !session.flag));
                    return;
                }
            history.push('/');
            dispatch(Actions.NOT_LOADING());
        }
    }, [session]);

    useEffect(() => {
        if (!isSaved) {
            if (profile.msisdn) {
                if (profile.msisdn !== '') {
                    loadRecords();
                    loadDetails();
                }
            }
        } else {
            const records = Cookie.get('saved_records');
            if (!records)
                if (profile.msisdn) {
                    if (profile.msisdn !== '') {
                        loadRecords();
                        loadDetails()
                    }
                }

        }
    }, [profile])

    useEffect(() => {
        let profile = Cookie.get('saved_profile');
    }, []);

    const loadRecords = async () => {
        let result = await recordsService(profile.msisdn, session.token);
        dispatch(Actions.LOADING());
        console.log('Resultado altan', result);
        if (result.status === 'OK') {
            Cookie.set('saved_records', JSON.stringify(result.data), {expires: 3});
            await dispatch(Actions.SET_RECORDS_DATA(result.data));
        }
        const date = new Date();
        const fecha = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        Cookie.set('saved_date', fecha, {expires: 3});
        Cookie.set('saved_data', 'OK', {expires: 3});
        dispatch(Actions.NOT_LOADING());
    }

    const load = async () => {
        let result = await profileService(session.token);

        console.log('Perfil', profile);
        if (result.status === 'OK') {
            await dispatch(Actions.SET_DATA(result.data.name, result.data.second_name, result.data.msisdn));
            Cookie.set('saved_profile', {
                name: result.data.name,
                second_name: result.data.second_name,
                msisdn: result.data.msisdn
            }, {expires: 3});
        } else {
            alert(result.description);
            Cookie.remove('xel_tk_client');
            Cookie.remove('saved_profile');
            Cookie.remove('saved_profile_details');
            Cookie.remove('saved_data');
            Cookie.remove('prepagos');
            dispatch(Actions.LOG_OUT());
            dispatch(Actions.NOT_LOADING());
            return;
        }


        // dispatch(Actions.NOT_LOADING());
    }

    const loadDetails = async () => {
        let result2 = await profileDetailService(profile.msisdn, session.token);
        let result = await LastChargesService(profile.msisdn, session.token);

        if (result2.status === 'OK') {
            if (result.status !== 'OK') {
                await dispatch(Actions.SET_DATA_DETAILS(result2.data));
                Cookie.set('saved_profile_details', result2.data, {expires: 3});
            } else {
                await dispatch(Actions.SET_DATA_DETAILS({...result2.data, charges: result.data}))
                Cookie.set('saved_profile_details', {...result2.data, charges: result.data}, {expires: 3});
            }
        }
    }
    const closeBackDrop = () => {
        dispatch(Actions.CLEAN_MESSAGE());
    }
    return <Grid container className={style.contenedor}>
        {
            messages.show ? <Backdrop className={style.backdrop} open={true} onClick={() => {
            }}>
                <Paper elevation={3} className={style.paper}>
                    <Grid container alignItems={'center'} justify={'center'} direction={'column'} spacing={1}>
                        <Grid item xs={10}>
                            <Typography align={'center'} className={style.text}>
                                {messages.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Button color={'primary'} onClick={closeBackDrop}>
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Backdrop> : ''
        }
        <Grid item xs={12} id={'container-principal'} className={style.contenedor}>
            {
                !loading ? <Rutas/> : <Loading/>
            }
        </Grid>
        <PwaInstall/>
    </Grid>

}

export default Contenedor;