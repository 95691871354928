import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';


import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';
import Typography from "@material-ui/core/Typography";

import BottomBar from "../../ui/BottomBar";
import Paper from "@material-ui/core/Paper";
import DateCard from "./DateCard";
import {XelIconApp, XelIconCalendar} from "../../assets/home/svg";
import Button from "@material-ui/core/Button";


const useStyle = makeStyles({
    contenedor: {
        backgroundColor: '#FEFEFE',
        height: '100%',
        position: 'relative',
        paddingTop: 70,
    },
    logo: {
        maxHeight: 67,
        height: '100%'
    },
    logo_container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '5%'
    },
    border_line: {
        backgroundColor: XEL_COLORS.primary,
        height: '32pt',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    border_line_w: {
        backgroundColor: XEL_COLORS.secondary,
        height: '32pt',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    border_text: {
        color: '#FEFEFE',
        fontSize: '15pt',
        fontWeight: XEL_FONT.bold_weight
    },
    sub_container: {
        //    padding: '1%',
        marginBottom: 70
    },
    date_text: {
        fontSize: '15pt',
        color: '#707070'
    },
    image_container: {
        display: 'flex',
        justifyContent: 'center'
    },
    card_container: {
        display: 'flex',
        justifyContent: 'center'
    },
    icon_phone: {
        stroke: 'none',
        fill: XEL_COLORS.primary
        ,
        height: '32pt',
        paddingRight: '2%'
    },
    plan_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    plan_text: {
        color: "#707070",
        fontSize: '15pt',
        paddingLeft: '1%',
        width: '129pt'
    },
    plan_text_date: {
        color: "#707070",
        fontSize: '15pt',
        paddingLeft: '1%',
    }
    ,
    button_factura: {
        color: '#FEFEFE',
        backgroundColor: XEL_COLORS.secondary,
        fontSize: '10pt',
        width: '136pt',
        height: '24pt',
        borderRadius: 0
    },
    button_historial: {
        color: XEL_COLORS.secondary,
        backgroundColor: '#FEFEFE',
        fontSize: '10pt',
        width: '136pt',
        height: '24pt',
        borderRadius: 0,
        border: `1px solid ${XEL_COLORS.secondary}`
    },
    button_factura_container: {
        display: 'flex',
        justifyContent: 'center'
    },
    txt_info: {
        color: "#707070",
        fontSize: '10pt'
    }
})
export default function Account() {

    const history = useHistory();
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const style = useStyle();
    const details = useSelector(state => state.profiledetail);
    const {
        name_product,
        pay,
        date_cut,
        date_final
    } = details;

    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


    const [month, setMonth] = useState('');
    const [payMonth, setPayMonth] = useState({
        month: '',
        number: 0
    });
    const date = new Date();
    useEffect(() => {
        console.log();
        if (parseInt(date_cut) > parseInt(date_final)) {
            setMonth(months[date.getMonth()]);
            setPayMonth({
                month: months[date.getMonth() + 1],
                number: date.getMonth() + 1
            });
        } else {
            setMonth(months[date.getMonth() + 1]);
            setPayMonth({
                month: months[date.getMonth()],
                number: date.getMonth()
            });
        }
    }, []);


    return <Grid container className={style.contenedor}>
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={3}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12} className={style.border_line}>
                    <Typography className={style.border_text}>
                        Estado de Cuenta
                    </Typography>
                </Grid>
                <Grid item xs={12} className={style.plan_container}>
                    <XelIconCalendar className={style.icon_phone}/>
                    <Typography className={style.plan_text_date} align={'center'}>
                        {month}{' '}{date.getFullYear()}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={style.border_line_w}>
                    <Typography className={style.border_text}>
                        Plan Contratado
                    </Typography>
                </Grid>
                <Grid item xs={12} className={style.plan_container}>
                    <XelIconApp className={style.icon_phone}/>
                    <Typography className={style.plan_text} align={'center'}>
                        {name_product}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={style.card_container}>
                    <DateCard month={payMonth.month} date={`${payMonth.number}/${date_final===13 ? '01':date_final}/${date_final===13?date.getFullYear()+1:date.getFullYear()}`}
                              total={pay}/>
                </Grid>
                {
                    /*  <Grid item xs={12} className={style.button_factura_container}>
                    <Button className={style.button_factura}>
                        Factura Actual
                    </Button>

                </Grid>  <Grid item xs={12} className={style.button_factura_container}>
                    <Button disabled className={style.button_historial}>
                        Historial

                    </Button>

                </Grid>*/
                }

                {
                    /* <Grid item xs={12}>
                    <Typography className={style.txt_info} align={'center'}>
                        Los pagos se verán reflejados en automático en tu saldo a pagar
                    </Typography>
                </Grid>*/
                }
            </Grid>
            <BottomBar/>
        </Grid>
    </Grid>
}