import {combineReducers, createStore} from 'redux';
import loggedReducer from "./LoginReducer";
import superLoadingReducer from "./SuperLoadingReducer";
import profileDataReducer from "./ProfileDataReducer";
import profileDetailReducer from "./ProfileDetailReducer";
import recordsReducer from "./RecordsReducer";
import messagesReducer from "./MessageReducer";
import prepagoSelPlanReducer from "./PrepagoSelPlanReducer";

const rootReducer = combineReducers({
    login: loggedReducer,
    superloading: superLoadingReducer,
    profile: profileDataReducer,
    profiledetail: profileDetailReducer,
    records: recordsReducer,
    messages: messagesReducer,
    prepagobuy: prepagoSelPlanReducer
});
/* eslint-disable no-underscore-dangle */
const myStore = createStore(
    rootReducer /* preloadedState, */
    , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */
export default myStore;