import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constants";
import Grid from "@material-ui/core/Grid";


// Inspired by the former Facebook spinners.
const useStylesXel = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: XEL_COLORS.primary,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
    text_title: {
        fontSize: '10pt',
        fontWeight: XEL_FONT.bold_weight
    },
    text_porcent: {
        fontSize: '15pt',
        fontWeight: XEL_FONT.bold_weight,

    },
    circle_container: {
        display: "flex",
        justifyContent: "center"
    }
}));

function XelCircularProgress(props) {
    const classes = useStylesXel();

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="static"
                className={classes.bottom}
                size={100}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="static"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={100}
                thickness={4}
                {...props}
                value={props.value}
            />
        </div>
    );
}


function CircularProgressWithLabel(props) {
    const style = useStylesXel();

    return (
        <Box position="relative" display="inline-flex">
            <XelCircularProgress  value={props.value}/>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" className={style.text_porcent}
                            color="secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and static variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function CircularStatic({value, text}) {
    const style = useStylesXel();
    return <Grid container>
        <Grid item xs={12} className={style.circle_container}>
            <CircularProgressWithLabel value={parseInt(value)}/>
        </Grid>
        <Grid item xs={12}>
            <Typography className={style.text_title} color="secondary" align={'center'}>
                {text}
            </Typography>
        </Grid>
    </Grid>;
}

CircularStatic.propTypes = {
    /**
     * The value of the progress indicator for the determinate and static variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
};
