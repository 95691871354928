const profileDetailReducer = (state = {
    name_product: "",
    pay: "",
    date_cut: "",
    date_final: "",
    charges: []
}, action) => {
    switch (action.type) {
        case "SET_DATA_DETAILS":
            return (state = action.payload);
        default:
            return state;
    }
};

export default profileDetailReducer;