const prepagoSelPlanReducer = (state = {
    id: "",
    pay: "",
    product:""
}, action) => {
    switch (action.type) {
        case "SET_PRE_BUYDET":
            return (state = action.payload);
        default:
            return state;
    }
};

export default prepagoSelPlanReducer;