import React, {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import XeLogo from '../../assets/inxel/logos/inxel-color.png';
import {XEL_COLORS, XEL_FONT} from "../../constants";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        backgroundColor: '#FEFEFE',
        borderRadius: 10,
        position: 'relative'
    },
    img: {
        width: 111,
        height: 70,
        // borderRadius: 10
    },
    center_container: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        backgroundColor: XEL_COLORS.secondary,
        color: '#FEFEFE',
        borderRadius: 30,
        padding: '5%',
        //  marginBottom: '2%'
    },
    ignore: {
        backgroundColor: '#FEFEFE',
        color: '#707070',
        borderRadius: 30,
        padding: '1%',
        fontSize: 10,

        //position: 'absolute',
        //bottom: -30,
        border: '1px solid #707070'

    }
}));
export default function PwaInstall() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    let deferredprompt;
    let isSupportingBrowser = false;


    let installed = false
    let hasprompt = false;
    let usecustom = false;
    let validToInstall = false;
    const [prompt, setPromt] = useState(null);
    useEffect(() => {
        if (prompt !== null)
            setOpen(true);
    }, [prompt]);
    useEffect(() => {
        /*  window.addEventListener('beforeinstallprompt', (e) => {
              // Prevent the mini-infobar from appearing on mobile
              e.preventDefault();
              // Stash the event so it can be triggered later.
              deferredPrompt = e;
              // Update UI notify the user they can install the PWA
              setOpen(true);
          });*/
        isSupportingBrowser = window.hasOwnProperty("BeforeInstallPromptEvent");
        console.log('Soporta', isSupportingBrowser)
        const IOS =
            navigator.userAgent.includes("iPhone") ||
            navigator.userAgent.includes("iPad") ||
            (navigator.userAgent.includes("Macintosh") &&
                navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2);
        console.log('Es IOS', isIOS);
        installed = false;


        if (IOS) {
            setOpen(true);
            setIsIOS(IOS);
        }
        // grab an install event
        window.addEventListener("beforeinstallprompt", (e) => handleInstallPromptEvent(e));

        window.addEventListener('appinstalled', (evt) => {
            // Log install to analytics
            //console.log('INSTALL: Success');
            setOpen(false);

        });
        if (window.matchMedia('(display-mode: standalone)').matches) {
            setOpen(false);
            // do things here
            // set a variable to be used when calling something
            // e.g. call Google Analytics to track standalone use
            window.dataLayer = window.dataLayer || [];

            function gtag() {
                window.dataLayer.push(arguments);
            }

            gtag('js', new Date());

            gtag('config', 'UA-178907281-1');
        }
    }, []);

    const handleInstall = async () => {

        prompt.prompt();

        // let event = new CustomEvent("show");
        //this.dispatchEvent(event);
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag('js', new Date());

        gtag('config', 'UA-178939156-1');

        prompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                setOpen(false);
                installed = true;
            } else {
                installed = true;
                setOpen(false);
            }
        });
    }
    const handleInstallPromptEvent = (e) => {
        hasprompt = true;
        setOpen(true);
        setPromt(e);
        e.preventDefault();
    }
    const handleClose = () => {
        setOpen(false);
    };

    return <Backdrop className={classes.backdrop} open={open}>
        <Grid container justify={'center'} spacing={3}>
            <Grid item xs={10} md={4} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.center_container}>
                        <img src={XeLogo} alt="PWA Logo" className={classes.img}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h6'} color={"primary"} align={'center'}
                                    style={{fontSize: '16pt', fontWeight: XEL_FONT.bold_weight}}>
                            Agrega inXel a tu pantalla de inicio
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body2'} style={{color: '#000000', fontSize: '12pt'}} align={'center'}>
                            Consulta tus consumos, domicilia tus tarjetas, ubica tus tiendas más cercanas para realizar
                            pagos sin tener que descargar absolutamente nada.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.center_container}>
                        {
                            !isIOS ? <Button className={classes.button} onClick={handleInstall}>
                                Agregar ahora
                            </Button> : <Typography color={'primary'} align={'center'}
                                                    style={{fontSize: 14, fontWeight: XEL_FONT.semi_bold_weight}}>
                                Pulsa opciones en tu navegador y después "Agregar a pantalla de inicio"
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.center_container}>
                        <Button onClick={handleClose} className={classes.ignore}>
                            Posponer
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Backdrop>
}