import React, {useEffect, useState} from "react";
import {Grid, Slide} from "@material-ui/core";

import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import Container from "@material-ui/core/Container";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import OpenPayLogo from "../../assets/logos/OpenPay/openpay_color.png";
import MasterCardLogo from "../../assets/logos/OpenPay/masterCard.png";
import VisaLogo from "../../assets/logos/OpenPay/visa.png";
import Amex from "../../assets/logos/OpenPay/americanExpress.png";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import Snackbar from "@material-ui/core/Snackbar";

import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from "@material-ui/core/CircularProgress";

import Confirm from '../../assets/images/confirmacion-01.svg';
import BottomBar from "../../ui/BottomBar";
import NavBar from "../../ui/NavBar";
import {useParams, useHistory} from 'react-router-dom';
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    rel_container: {
        position: "relative",

    },
    degrated_container: {
        position: "absolute",
        zIndex: -1,
        height: '100%',
        width: '100%',
        background: 'transparent linear-gradient(74deg, #F6DED1 0%, #E5C0AC 6%, #662D91 84%) 0% 0% no-repeat padding-box',
        opacity: 0.87
    },
    title: {
        color: '#FEFEFE',
        textAlign: 'center',
        marginTop: 20
    },
    body: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: '#FEFEFE',
        textAlign: 'justify',
        padding: '20px 50px'
    },
    banner: {
        backgroundColor: XEL_COLORS.primary,
        marginTop: 40
    },
    banner_text: {
        // color: '#FEFEFE',
        marginTop: '2%'
    },
    step_container: {
        // border: `1px solid ${XEL_COLORS.primary}`,
        boxShadow: '8px 3px 6px #00000029',
        padding: 10,
        marginTop: 20,
        overflow: "hidden"
    },
    input: {
        marginTop: 10
    },
    next: {
        color: '#FEFEFE',
        marginTop: 25,
        borderRadius: 0
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddCard() {
    const style = useStyles();
    const user = useSelector(state => state.profile);
    const history = useHistory();
    const [direction, setDirection] = useState('left');
    const session = useSelector(state => state.login);
    const mailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // eslint-disable-next-line no-undef
    let OpenPayInstance = OpenPay;
    const [active, setActive] = useState(0);
    /**********Seccion Pago*************/
    let date = new Date();
    const [infoPago, setInfoPago] = useState({
        name_card: '',
        card: '',
        month: '1',
        year: date.getFullYear().toString(),
        cvv: ''
    });
    const params = useParams();
    const [phone, setPhone] = useState(params.mode === 'mixel' ? user.msisdn : '');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)
    const [infoPagoErrors, setInfoPagoErrors] = useState({
        err_name_card: false,
        mg_name_card: '',
        err_card: false,
        mg_card: '',
        err_month: false,
        mg_month: '',
        err_year: false,
        mg_year: '',
        err_cvv: false,
        mg_cvv: '',
        status_pay: false,
        msg_pay: ''
    });
    const [monto, setMonto] = useState(0);
    const [product, setProduct] = useState('');
    const [completed, setCompleted] = useState(false);
    const onlyLettersReg = /^[a-zA-Z\s]*$/;
    const onlyNumber = /^[0-9]+$/;
    const [open, setOpen] = React.useState({
        open: false,
        severity: 'success',
        msg: ''
    });
    const profile = useSelector(state => state.profile);
    const [mine, setMine] = useState(false);


    const Mont = [{
        id: '1',
        name: 'Enero'
    },
        {
            id: '2',
            name: 'Febrero'
        },
        {
            id: '3',
            name: 'Marzo'
        },
        {
            id: '4',
            name: 'Abril'
        },
        {
            id: '5',
            name: 'Mayo'
        },
        {
            id: '6',
            name: 'Junio'
        },
        {
            id: '7',
            name: 'Julio'
        },
        {
            id: '8',
            name: 'Agosto'
        },
        {
            id: '9',
            name: 'Septiembre'
        },
        {
            id: '10',
            name: 'Octubre'
        },
        {
            id: '11',
            name: 'Noviembre'
        },
        {
            id: '12',
            name: 'Diciembre'
        },
    ];

    const Years = () => {
        const date = new Date();
        let year = date.getFullYear();
        let years = [];
        for (let i = 0; i < 13; i++)
            years.push(year++);
        return years;
    }


    const handleChangeRecurrent = e => {
        let namerr_check_reset = `err_${[e.target.name]}`;
        let namerr_msg_reset = `msg_${[e.target.name]}`;
        setInfoPagoErrors({
            ...infoPagoErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: ''
        });

        switch (e.target.name) {
            case 'name_card':
                if (onlyLettersReg.test(e.target.value)) {

                    setInfoPago({
                        ...infoPago,
                        name_card: e.target.value
                    })
                }
                break;
            case 'card':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        card: ''
                    })
                else if (e.target.value.split('').length < 17)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            card: e.target.value
                        })
                    }
                break;
            case 'month':
                setInfoPago({
                    ...infoPago,
                    month: e.target.value
                })
                break;
            case 'year':

                setInfoPago({
                    ...infoPago,
                    year: e.target.value + ""
                });

                break;
            case 'cvv':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        cvv: ''
                    })
                else if (e.target.value.split('').length < 5)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            cvv: e.target.value
                        })
                    }
                break;
        }
    }
    const handlerBack = () => {
        setDirection('right');
        setActive(active - 1);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen({
            ...open,
            open: false,
        });
    };

    const handlerNext = async () => {
        setLoading(true);
        switch (active) {
            case 0:
                let {
                    name_card,
                    card,
                    month,
                    year,
                    cvv,
                } = infoPago;

                let arrayErrs3 = [];
                // eslint-disable-next-line no-undef
                //console.log(OpenPayInstance.token.create, 's')

                if (name_card.split('').length === 0) {
                    let err = {
                        err_name: 'err_name_card',
                        err_msg_name: 'msg_name_card',
                        err_msg: 'Inserta nombre completo'
                    }
                    arrayErrs3.push(err);
                }
                if (card.split('').length === 0) {
                    let err = {
                        err_name: 'err_card',
                        err_msg_name: 'msg_card',
                        err_msg: 'Inserta tarjeta'
                    }
                    arrayErrs3.push(err);
                } else if (card.split('').length < 15) {
                    let err = {
                        err_name: 'err_card',
                        err_msg_name: 'msg_card',
                        err_msg: 'Inserta tarjeta valida'
                    }
                    arrayErrs3.push(err);
                }
                if (cvv.split('').length === 0) {
                    let err = {
                        err_name: 'err_cvv',
                        err_msg_name: 'msg_cvv',
                        err_msg: 'Inserta CVV'
                    }
                    arrayErrs3.push(err);
                } else if (cvv.split('').length < 3) {
                    let err = {
                        err_name: 'err_cvv',
                        err_msg_name: 'msg_cvv',
                        err_msg: 'CVV invalido'
                    }
                    arrayErrs3.push(err);
                }

                let newErrVals3 = infoPagoErrors;
                for (let i = 0; i < arrayErrs3.length; i++) {
                    newErrVals3 = {
                        ...newErrVals3,
                        [arrayErrs3[i].err_name]: true,
                        [arrayErrs3[i].err_msg_name]: [arrayErrs3[i].err_msg],
                    }
                }
                setInfoPagoErrors(newErrVals3);
                if (arrayErrs3.length > 0) {
                    setLoading(false);
                    return;
                }
                let confirm;
                if (card.split('').length === 15)
                    confirm = window.prompt('Digita los ultimos 3 digitos de tu tarjeta para confirmar');
                else
                    confirm = window.prompt('Digita los ultimos 4 digitos de tu tarjeta para confirmar');

                let lastFour = '';

                for (let i = 12; i < infoPago.card.split('').length; i++) {
                    lastFour += infoPago.card.charAt(i);
                }
                if ((confirm + '') !== (lastFour + '')) {
                    setOpen({
                        open: true,
                        severity: 'error',
                        msg: 'La confirmación no coincide.',
                    });
                    setLoading(false);
                    return;
                }

                OpenPayInstance.token.create({
                    "card_number": infoPago.card,
                    "holder_name": infoPago.name_card.trim(),
                    "expiration_year": `${infoPago.year.charAt(2)}${infoPago.year.charAt(3)}`,
                    "expiration_month": infoPago.month,
                    "cvv2": infoPago.cvv,
                }, (data) => {
                    const tokenid = data.data.id;
                    const deviceDataId = OpenPayInstance.deviceData.setup("frm_getcard");

                    const bodyReq = {
                        token: session.tk,
                        id_card: tokenid,
                        device_id: deviceDataId,
                        mode: 'prod'
                    }
                    fetch('https://inxel.mx/api/xel/v1/pwa.save.card.json', {
                        method: 'POST',
                        body: JSON.stringify(bodyReq)
                    }).then(response => response.json())
                        .then(response => {

                            if (response.status) {
                                setCompleted(true);
                                setLoading(false);
                                return;
                            } else if (response.error) {
                                setOpen({
                                    open: true,
                                    severity: 'error',
                                    msg: response.error_description
                                });
                                setLoading(false);
                                return;
                            }
                            setOpen({
                                open: true,
                                severity: 'error',
                                msg: "Error desconocido, vuelva a intentarlo"
                            })
                            setLoading(false);

                        }).catch(error => {
                        setOpen({
                            open: true,
                            severity: 'error',
                            msg: 'Error de conexión, intente de nuevo'
                        });
                        setLoading(false);
                        console.log(error);
                    });
                }, (error) => {
                    //alert('Hubo un Error');
                    if (error.data.error_code === 1018) {

                        setOpen({
                            open: true,
                            severity: 'error',
                            msg: 'Error desconocido'
                        })

                    } else if (error.data.error_code === 2005) {
                        setOpen({
                            open: true,
                            severity: 'error',
                            msg: 'Tarjeta vencida'
                        })
                    } else if (error.data.error_code === 1001) {
                        setOpen({
                            open: true,
                            severity: 'error',
                            msg: 'Verifique datos de tarjeta'
                        })
                    } else {
                        setOpen({
                            open: true,
                            severity: 'error',
                            msg: 'Error, verifique los datos de la tarjeta.'
                        })
                    }
                    console.log(error.data, 'Error tokens')
                    setLoading(false);
                    //  dispatch(Actions.BUY_PROCESS_END());
                });
                break;
        }
    }
    const getSecretCard = () => {
        let card = infoPago.card;
        let four = '';
        card = card.split('');
        for (let i = 12; i < 16; i++) {
            four += card[i];
        }
        return `************${four}`;
    }
    const getExp = () => {
        let year = `${infoPago.year.charAt(2)}${infoPago.year.charAt(3)}`;
        return `${infoPago.month}/${year}`;
    }


    const Step4 = (
        <Slide in={true} direction={direction}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                        <img src={Confirm} alt="Imagen" style={{height: 174, width: 174}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h6'} align={'center'} color={'primary'}>
                            SU TARJETA SE GUARDO CORRECTAMENTE. GRACIAS
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                        <Button variant={'contained'} color={'secondary'} style={{color: '#FEFEFE'}} onClick={() => {
                            window.location.reload();
                        }}>
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    );


    const Step2 = (
        <Slide in={true} direction={direction}>
            <Grid item xs={12}>
                <Container component={'form'} id={'frm_getcard'}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container style={{marginBottom: 20}}>
                                <Grid item xs={12} md={12}>

                                    <TextField id="name" name={'name_card'}
                                               label="Nombre completo"
                                               value={infoPago.name_card}
                                               onChange={handleChangeRecurrent} error={infoPagoErrors.err_name_card}
                                               helperText={infoPagoErrors.msg_name_card} fullWidth/>

                                </Grid>
                            </Grid>
                            <Grid container style={{
                                marginBottom: 20,
                            }} spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <TextField id="card-number" label="Número de tarjeta" name={'card'}
                                               onChange={handleChangeRecurrent}
                                               error={infoPagoErrors.err_card} helperText={infoPagoErrors.msg_card}
                                               value={infoPago.card} fullWidth/>
                                </Grid>
                                <Grid item xs={12} md={4}
                                      style={{display: "flex", justifyContent: "center", padding: '2%'}}>
                                    <img src={MasterCardLogo} alt="MasterCard" style={{height: 43, marginRight: 10}}/>
                                    <img src={VisaLogo} alt="Visa" style={{height: 43, marginLeft: 10}}/>
                                    <img src={Amex} alt="AMEX" style={{height: 43, marginLeft: 10}}/>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: 20}} spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="month">Mes</InputLabel>
                                        <Select
                                            labelId="month"
                                            id="month"
                                            name={'month'}
                                            value={infoPago.month}
                                            onChange={handleChangeRecurrent}
                                        >
                                            {
                                                Mont.map(month => <MenuItem value={month.id}
                                                                            key={month.id}>{month.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="year">Año</InputLabel>
                                        <Select
                                            labelId="year"
                                            id="year"
                                            name={'year'}
                                            value={infoPago.year}
                                            onChange={handleChangeRecurrent}
                                        >
                                            {
                                                Years().map(year => <MenuItem value={year} key={year}>{year}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextField id="cvv" label="CVV" name={'cvv'} value={infoPago.cvv} type={'password'}
                                               error={infoPagoErrors.err_cvv} helperText={infoPagoErrors.msg_cvv}
                                               onChange={handleChangeRecurrent} fullWidth/>
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                            {
                                loading ?
                                    <Button disabled className={style.next} variant={'contained'}
                                            color={'secondary'}
                                            startIcon={<CircularProgress color="primary"/>}>

                                        Procesando</Button> :
                                    <Button className={style.next} onClick={handlerNext} variant={'contained'}
                                            color={'secondary'}>
                                        Guardar</Button>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Slide>);

    return <>
        <NavBar/>
        <Container style={{marginTop: 100}}>
            <Grid container>
                <Grid item xs={12} style={{marginTop: '2%'}}>
                    <Typography variant={'body1'} color={'primary'}
                                style={{fontWeight: XEL_FONT.bold_weight, fontSize: 25}}
                                align={'center'}>
                        Registra los datos de tu tarjeta
                    </Typography>
                    <br/>
                </Grid>
            </Grid>
        </Container>
        <Grid container>
            <Grid item xs={12}>
                <Grid container justify={'center'}>
                    <Grid item xs={12}  className={style.step_container}>
                        {
                            !completed ? <>
                                {
                                    active === 0 ? Step2 : ''
                                }
                            </> : Step4
                        }
                    </Grid>
                </Grid>
                <Snackbar open={open.open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={open.severity}>
                        {open.msg}
                    </Alert>
                </Snackbar>
            </Grid>
            <Grid item xs={12} style={{marginTop: '2%'}}>
                <Typography variant={'body1'} align={'center'} className={style.banner_text}
                            style={{fontSize: 12}}>
                    **Nota:** Todas las tarjetas al momento de guardarse son validadas haciendo una autorización por $10.00 los cuales son devueltos en el momento.
                </Typography>
            </Grid>

            <Grid item xs={12} style={{marginTop: '2%'}}>
                <Typography variant={'body1'} align={'center'} className={style.banner_text}
                            style={{fontSize: 15}}>
                    Pagos seguros con
                </Typography>
            </Grid>
            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                <img src={OpenPayLogo} alt="OpenPay" style={{maxWidth: 160, maxHeight: 50,}}/>
            </Grid>
        </Grid>
        <BottomBar/>
    </>
}