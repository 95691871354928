import React, {useEffect, useState} from "react";
import {Container, Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constants";
import Typography from "@material-ui/core/Typography";
import DoneIcon from '@material-ui/icons/Done';


const useStyles = makeStyles({
    container: {
        position: "relative",
        marginTop: 50
    },
    circle: {
        height: 48,
        width: 48,
        backgroundColor: XEL_COLORS.primary,
        borderRadius: '50%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },
    circle_selected: {
        border: `2px solid ${XEL_COLORS.primary}`,
        backgroundColor: "#FEFEFE",
    },
    circle_text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        // fontSize: 26,
        color: '#FEFEFE'
    },
    circle_text_selected: {

        color: XEL_COLORS.primary
    },
    container_circle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative"
    },
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.light_weight,
        textAlign: "center",
        padding: 10
        //  fontSize: 18
    },
    line_container1: {
        position: "absolute",
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: "flex-end",
        zIndex: 0
    },
    line: {
        height: 2,
        width: '50%',
        backgroundColor: XEL_COLORS.primary,
    },
    line_container2: {
        position: "absolute",
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: "flex-start",
        zIndex: 0
    }
});
export default function Steps({active}) {
    const style = useStyles();
    const noCel = useMediaQuery('(min-width:426px)');
    return <Container>
        <Grid container className={style.container}>
            <Grid item xs={4} className={style.container_circle}>
                <div className={`${style.circle} ${active === 0 ? style.circle_selected : ''}`}
                     style={!noCel ? {width: 25, height: 25} : {}}>

                    {active > 0 ?
                        <DoneIcon color={'secondary'}/> :
                        <Typography className={`${style.circle_text} ${active === 0 ? style.circle_text_selected : ''}`}
                                    style={{fontSize: !noCel ? 20 : 26}}>
                            1
                        </Typography>
                    }
                </div>
                <Typography className={style.text} style={{fontSize: !noCel ? 11 : 18}}>
                    Ingresa tu número
                </Typography>
                <div className={style.line_container1} style={{paddingTop: !noCel ? 12.5 : 24}}>
                    <span className={style.line} style={{width: !noCel ? 'calc(50% - 12.5px)' : 'calc(50% - 24px)'}}/>
                </div>
            </Grid>
            <Grid item xs={4} className={style.container_circle}>
                <div className={style.line_container2} style={{paddingTop: !noCel ? 12.5 : 24}}>
                    <span className={style.line} style={{width: !noCel ? 'calc(50% - 12.5px)' : 'calc(50% - 24px)'}}/>
                </div>
                <div className={`${style.circle} ${active === 1 ? style.circle_selected : ''}`}
                     style={!noCel ? {width: 25, height: 25} : {}}>
                    {active > 1 ?
                        <DoneIcon color={'secondary'}/> :
                        <Typography className={`${style.circle_text} ${active === 1 ? style.circle_text_selected : ''}`}
                                    style={{fontSize: !noCel ? 20 : 26}}>
                            2
                        </Typography>
                    }
                </div>
                <Typography className={style.text} style={{fontSize: !noCel ? 11 : 18}}>
                    Información de pago
                </Typography>
                <div className={style.line_container1} style={{paddingTop: !noCel ? 12.5 : 24}}>
                    <span className={style.line} style={{width: !noCel ? 'calc(50% - 12.5px)' : 'calc(50% - 24px)'}}/>
                </div>
            </Grid>
            <Grid item xs={4} className={style.container_circle}>
                <div className={style.line_container2} style={{paddingTop: !noCel ? 12.5 : 24}}>
                    <span className={style.line} style={{width: !noCel ? 'calc(50% - 12.5px)' : 'calc(50% - 24px)'}}/>
                </div>
                <div className={`${style.circle} ${active === 2 ? style.circle_selected : ''}`}
                     style={!noCel ? {width: 25, height: 25} : {}}>
                    {active > 2 ?
                        <DoneIcon color={'secondary'}/> :
                        <Typography className={`${style.circle_text} ${active === 2 ? style.circle_text_selected : ''}`}
                                    style={{fontSize: !noCel ? 20 : 26}}>
                            3
                        </Typography>
                    }
                </div>
                <Typography className={style.text} style={{fontSize: !noCel ? 11 : 18}}>
                    Confirmación
                </Typography>
            </Grid>
        </Grid>
    </Container>
}