import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import Logo from '../../assets/inxel/logos/inxel-color.png';


import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../app/actions';
import Typography from "@material-ui/core/Typography";
import CircularStatic from "./Progress";
import BottomBar from "../../ui/BottomBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import ProgressLine from "./ProgressLine";
import UpdatedAt from "../../ui/UpdatedAt";
import {XelIconCalendar} from "../../assets/home/svg";

const useStyle = makeStyles({
    contenedor: {
        backgroundColor: '#FEFEFE',
        height: '100%',
        position: 'relative',
        paddingTop: 80,

    },
    logo: {
        maxHeight: 67,
        height: '100%'
    },
    logo_container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '5%'
    },
    border_line: {
        backgroundColor: XEL_COLORS.primary,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    border_text: {
        color: '#FEFEFE',
        fontSize: 15,
        fontWeight: XEL_FONT.bold_weight
    },
    sub_container: {
        //    padding: '1%',
        marginBottom: 70
    },
    date_text: {
        fontSize: '15pt',
        color: '#707070'
    },
    image_container: {
        display: 'flex',
        justifyContent: 'center'
    },
    records_container: {
        marginTop: '5%'
    },
    calendar: {
        height: '32pt',
        fill: XEL_COLORS.primary,
        stroke: 'none'
    },
    icon_container: {
        display: 'flex',
        justifyContent: 'center'
    }


})
export default function Records() {

    const history = useHistory();
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const style = useStyle();
    const records = useSelector(state => state.records);

    const [data, setData] = useState({
        dataTotal: 0,
        dataUsed: 0,
        dataPercent: 0,
        minTotal: 0,
        minUsed: 0,
        minPercent: 0,
        SMSTotal: 0,
        SMSUsed: 0,
        SMSPercent: 0,
    });

    useEffect(() => {
        console.log('Datos', data);
    }, [data])
    useEffect(() => {
        console.log('Records', records);
        if (records.loaded) {
            const codeData = 'FU_Data';
            const codeData2 = 'FreeData';
            const codeData3 = 'FU_ThrMBB';
            const codeMin = 'FU_Min_Altan-NR';
            const codeSMS = 'FU_SMS_Altan-NR';

            let dataTotal = 0;
            let dataNoUsed = 0;
            let minTotal = 0;
            let minNoUsed = 0;
            let SMSTotal = 0;
            let SMSNoUsed = 0;
            const recordsData = records.data;
            const units = recordsData.responseSubscriber.freeUnits;

            for (let i = 0; i < units.length; i++) {
                const unit = units[i]
                let name = unit.name;
                let compareName = "";
                for (let j = 0; j < name.split('').length; j++) {
                    compareName += name.charAt(j);

                    if (compareName === codeData || compareName === codeData2 || compareName === codeData3) {
                        dataTotal += parseInt(unit.freeUnit.totalAmt);
                        dataNoUsed += parseInt(unit.freeUnit.unusedAmt);
                        continue;
                    }
                    if (compareName === codeMin) {
                        minTotal += parseInt(unit.freeUnit.totalAmt);
                        minNoUsed += parseInt(unit.freeUnit.unusedAmt);
                        continue;
                    }
                    if (compareName === codeSMS) {
                        SMSTotal += parseInt(unit.freeUnit.totalAmt);
                        SMSNoUsed += parseInt(unit.freeUnit.unusedAmt);
                        continue;
                    }
                }
            }

            const dataPercent = 100 - (dataNoUsed / (dataTotal / 100));
            const minPercent = 100 - (minNoUsed / (minTotal / 100));
            const SMSPercent = 100 - (SMSNoUsed / (SMSTotal / 100));
            const gigas = (dataTotal / 1000);
            setData
            ({
                dataTotal: parseInt(gigas),
                dataUsed: dataTotal - dataNoUsed,
                dataPercent: dataPercent,
                minTotal: minTotal,
                minUsed: minTotal - minNoUsed,
                minPercent: minPercent,
                SMSTotal: SMSTotal,
                SMSUsed: SMSTotal - SMSNoUsed,
                SMSPercent: SMSPercent,
            });
        }
    }, [records]);

    return <Grid container className={style.contenedor}>
        <Grid item xs={12}>
            <NavBar/>
            <Grid container className={style.sub_container} spacing={4}>
                <Grid item xs={12} className={style.logo_container}>
                    <img src={Logo} alt="inXel" className={style.logo}/>
                </Grid>
                <Grid item xs={12} className={style.border_line}>
                    <Typography className={style.border_text}>
                        Consumo de este periodo
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs>
                            <CircularStatic value={data.dataPercent} text={'Navegación'}/>
                        </Grid>
                        <Grid item xs>
                            <CircularStatic value={data.SMSPercent} text={'SMS'}/>
                        </Grid>
                        <Grid item xs>
                            <CircularStatic value={data.minPercent} text={'Minutos'}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className={style.icon_container}>
                            <XelIconCalendar className={style.calendar}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={style.date_text} align={'center'}>
                                Consumo actual
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={style.records_container}>
                    <Grid item xs={12}>
                        <ProgressLine title={'Navegación'} porcent={data.dataPercent}
                                      body={`Has usado ${data.dataUsed} MB de ${data.dataTotal}GB`}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ProgressLine title={'SMS'} porcent={data.SMSPercent}
                                      body={`Has usado ${data.SMSUsed} SMS de ${data.SMSTotal}`}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ProgressLine title={'Minutos'} porcent={data.minPercent}
                                      body={`Has usado ${data.minUsed} minutos de ${data.minTotal}`}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <UpdatedAt/>
                </Grid>
            </Grid>
            <BottomBar/>
        </Grid>
    </Grid>
}