import Axios from "axios";

export default async function logginService(email, password) {
    const login = new Promise((resolve, reject) => {
        let data = {
            email,
            password
        };
   let url = process.env.NODE_ENV ==='development' ? "http://localhost:3000/clients/login":'https://api.inxel.mx/clients/login/v2'
        Axios.post(url, data).then(res => {
            const response = res.data;
            if (response.error) {
                reject({
                    status: 'ERROR',
                    description: response.error_description,
                    code: response.error_code,
                    token: response.error_code === 400 ? response.token : '',
                    s_token:response.s_token
                });
                return;
            }
            resolve({
                status: 'OK',
                token: response.token,
                s_token: response.tk
            });
        }).catch(err => {
            console.log(err)
            reject({
                status: 'ERROR',
                description: 'Error al consultar datos',
                code: 500,
                token: ''
            });
        });

    });

    const result = login.then(response => {
        return response;
    }).catch(error => {
        return error;
    });

    return await result;
}
