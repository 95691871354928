import React, {useEffect} from "react";
import Login from "../pages/Login";
import {BrowserRouter as Router, Switch, Route, useLocation} from "react-router-dom";
import Home from "../pages/Home";
import Menu from "../pages/Menu";
import {
    TransitionGroup, CSSTransition
} from "react-transition-group";
import Records from "../pages/Records";
import Account from "../pages/Account";
import Reference from "../pages/Reference";
import Help from "../pages/Help";
import PayOnline from "../pages/PayOnline";
import Services from "../pages/Services";
import PagarAhora from "../pages/PayNow";
import AddCard from "../pages/AddCard";
import PwaInstall from "../ui/PwaInstall";
import {Grid} from "@material-ui/core";
import Chat from "../pages/Chat";


const Routes = [
    {
        Title: 'Menu',
        Component: Menu,
        Path: '/menu',
        exact: true
    },
    {
        Title: 'Iniciar Sesión',
        Component: Login,
        Path: '/',
        exact: true
    },
    {
        Title: 'Inicio',
        Component: Home,
        Path: '/inicio',
        exact: true
    },
    {
        Title: 'Consumo',
        Path: '/consumo',
        Component: Records,
        exact: true
    },
    {
        Title: 'Estado de cuenta',
        Path: '/cuenta',
        Component: Account,
        exact: true
    },
    {
        Title: 'Referencia',
        Path: '/referencia',
        Component: Reference,
        exact: true
    },
    {
        Title: 'Ayuda',
        Path: '/ayuda',
        Component: Help,
        exact: true
    },
    {
        Title: 'Pagar en línea',
        Path: '/pagar',
        Component: PayOnline,
        exact: true
    },
   /* {
        Title: 'Servicios',
        Path: '/servicios',
        Component: Services,
        exact: true
    },
    {
        Title: 'Configuracion',
        Path: '/configuracion',
        exact: true
    },*/
    {
        Title: 'Pagar ahora',
        Path: '/pagar-ahora/:mode',
        Component: PagarAhora,
        exact: true
    }, {
        Title: 'Añadir tarjeta',
        Path: '/tarjeta',
        Component: AddCard,
        exact: true
    },
    {
        Title: 'Chat Online',
        Path: '/chat',
        Component: Chat,
        exact: true
    }
];

const Rutas = () => {
    const location = useLocation();

    return <TransitionGroup component={'span'}>
        <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={300}
        >
            <Switch>
                {
                    Routes.map(route => {
                        if (route.Component)
                            return <Route path={route.Path} exact={route.exact} key={route.Title}>
                                <route.Component/>
                            </Route>
                    })
                }

            </Switch>
        </CSSTransition>

    </TransitionGroup>
}

export default Rutas;