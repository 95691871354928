import React, {useEffect} from "react";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS} from "../../../constants";
import Stat from "./Stat";
import {XelIconApp, XelIconCalendar, XelIconCalendarPoint, XelIconPaySign} from "../../../assets/home/svg";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

export default function Stats({color}) {

    const details = useSelector(state => state.profiledetail);
    const {
        name_product,
        pay,
        date_cut,
        date_final,
        charges
    } = details;

    const chargesTam = charges.length;

    const isPrepago = parseInt(date_cut) === 0;
    const history = useHistory();
    const date = new Date();
    const actualYear = date.getFullYear();
    const actualMonth = date.getMonth();
    const actualDay = date.getMonth();
    let fechaCorte = '';
    let fechaFinal = '';
    let fechaRenov = parseInt(date_final) + 1;
    if (fechaRenov < 10) {
        fechaRenov = '0' + fechaRenov;
    }
    if (parseInt(date_cut) < parseInt(date_final) && parseInt(date_final) > actualDay) {
        fechaCorte = `${date_cut}/${actualMonth + 1}/${actualYear}`;
        fechaFinal = `${date_final}/${actualMonth + 1}/${actualYear}`;
    } else if (parseInt(date_cut) < parseInt(date_final) && parseInt(date_final) < actualDay) {
        fechaCorte = `${date_cut}/${actualMonth + 2}/${actualYear}`;
        fechaFinal = `${date_final}/${actualMonth + 2}/${actualYear}`;
    } else {
        fechaCorte = `${date_cut}/${actualMonth + 1}/${actualYear}`;

        fechaFinal = `${date_final}/${actualMonth + 2}/${actualYear}`;
        if ((actualMonth + 2) === 14) {
            fechaFinal = `${date_final}/01/${actualYear + 1}`;
        }
    }

    return <Grid container>
        <Grid item xs={12}>

            {
                !isPrepago ? <>
                        <Stat title={'Plan contratado'} icon={XelIconApp} text={name_product}/>
                        <div onClick={() => {
                            //    if (parseInt(pay) !== 0) {
                            history.push('/pagar');
                            //      }
                        }}>
                            <Stat btn title={'Saldo a pagar'} color={'secondary'} icon={XelIconPaySign}
                                  text={`$ ${pay}.00`}/>
                        </div>

                        <Stat title={'Fecha límite de pago'} icon={XelIconCalendarPoint}
                              text={`${date_final} de cada mes`}>
                            <Typography style={{fontSize: 12, marginBottom: '2%'}} align={'center'}>
                                Puedes pagar desde {date_cut} al {parseInt(date_final) - 1} de cada mes
                            </Typography>
                        </Stat>
                        <Stat title={'Fecha de carga de plan voz y datos'} color={'secondary'} icon={XelIconCalendar}
                              text={`${fechaRenov} de cada mes`}/></> :
                    <>{
                        //Prepagooooo
                    }
                        {
                            chargesTam > 0 ? <>
                                    <Stat title={'Recarga actual'} icon={XelIconApp} text={charges[0].plan}/>
                                    <div onClick={() => {
                                        //    if (parseInt(pay) !== 0) {
                                        history.push('/pagar');
                                        //      }
                                    }}>
                                        <Stat btn title={'Monto de la ultima recarga'} color={'secondary'}
                                              icon={XelIconPaySign}
                                              text={`$ ${charges[0].precio}`}/>
                                    </div>
                                    <Stat title={'Vigencia ultima recarga'}
                                        //icon={XelIconCalendarPoint}
                                        //      text={`10/10/20 - 11/10/2020`}
                                    >
                                        <Grid container style={{marginTop: 10, marginBottom: 10}}>
                                            <Grid item xs={6}>
                                                <Typography align={'center'} style={{fontSize: '10pt', color: '#707070'}}>
                                                    Fecha de pago
                                                </Typography>
                                                <Typography align={'center'} style={{fontSize: '12pt', color: '#707070'}}>
                                                    {charges[0].fein}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography align={'center'} style={{fontSize: '10pt', color: '#707070'}}>
                                                    Fecha de expiración
                                                </Typography>
                                                <Typography align={'center'} style={{fontSize: '12pt', color: '#707070'}}>
                                                    {charges[0].feex}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Stat>
                                    <Stat title={'Ultimas 6 recargas'} color={'secondary'}
                                        //   icon={XelIconCalendar}
                                        //      text={``}
                                    >
                                        <Grid container spacing={2}>
                                            {
                                                charges.map((charge, index) => <>
                                                    {
                                                        index !== charges.length - 1 ?
                                                            <Grid item xs={12} style={{marginTop: 15}}>
                                                                <Grid container alignItems={'center'}>
                                                                    <Grid item xs={4} style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}>
                                                                        <Typography align={'center'} style={{
                                                                            fontSize: '10pt',
                                                                            color: '#707070'
                                                                        }}>
                                                                            {charge.plan}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Typography align={'center'} style={{
                                                                            fontSize: '10pt',
                                                                            color: '#707070'
                                                                        }}>
                                                                            Fecha pago
                                                                        </Typography>
                                                                        <Typography align={'center'} style={{
                                                                            fontSize: '12pt',
                                                                            color: '#707070'
                                                                        }}>
                                                                            {charge.fein}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>

                                                                        <Typography align={'center'} style={{
                                                                            fontSize: '10pt',
                                                                            color: '#707070'
                                                                        }}>
                                                                            Fecha expiración
                                                                        </Typography>
                                                                        <Typography align={'center'} style={{
                                                                            fontSize: '12pt',
                                                                            color: '#707070'
                                                                        }}>
                                                                            {charge.feex}
                                                                        </Typography>

                                                                    </Grid>
                                                                </Grid>
                                                            </Grid> : ""
                                                    }
                                                </>)
                                            }
                                        </Grid>
                                    </Stat>
                                </>
                                : <>
                                    <div onClick={() => {
                                        //    if (parseInt(pay) !== 0) {
                                        history.push('/pagar');
                                        //      }
                                    }}>
                                        <Stat btn title={'Monto de la ultima recarga'} color={'secondary'}
                                              icon={XelIconPaySign}
                                              text={`$ 0.00`}/>
                                    </div>
                                    <Stat title={'Vigencia ultima recarga'} icon={XelIconCalendarPoint}
                                          text={`10/10/20 - 11/10/2020`}>
                                        <Typography style={{fontSize: 12, marginBottom: '2%'}} align={'center'}>
                                            Puedes pagar desde {date_cut} al {parseInt(date_final) - 1} de cada mes
                                        </Typography>
                                    </Stat>
                                </>
                        }
                    </>
            }
        </Grid>
    </Grid>
}