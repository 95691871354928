import React, {useEffect, useState} from "react";
import Loading from "../../ui/Loading";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {API, XEL_COLORS, XEL_FONT} from "../../constants";
import Logo from "../../assets/inxel/logos/logo-inxel-blanco.png";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from '@material-ui/icons/AccountCircle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import Zoom from "@material-ui/core/Zoom";
import logginService from "../../services/LogginService";
//import Zoom from "@material-ui/core/Zoom";
import Cookie from 'js-cookie';
import * as Actions from '../../app/actions';
import CircularProgress from "@material-ui/core/CircularProgress";
import Reload from "../../app/functions/Reload";
import '@pwabuilder/pwainstall';
import './pwa.css';

import Fondo from '../../assets/images/fondo.svg';
import Backdrop from "@material-ui/core/Backdrop";
import Card from "@material-ui/core/Card";
import inXel from '../../assets/inxel/logos/inxel-color.png';

const usestyles = makeStyles({
    contenedor: {
        backgroundColor: XEL_COLORS.primary,
        height: '100%',
        backgroundImage: `url(${Fondo})`,
        backgroundSize: 'cover'
    },
    img: {
        maxWidth: 175,
        width: '100%',
        maxHeight: 105,
        marginBottom: 5
    },
    _container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'fit-content'
    },
    input: {
        color: '#FEFEFE'
    },
    msg_nop: {
        fontSize: 13,
        color: '#FEFEFE',
        fontWeight: XEL_FONT.semi_bold_weight
    }

})

const XelTextField = withStyles({
    root: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        backgroundColor: 'rgba(254,254,254,0.9)',
        '& input': {
            //color: '#FEFEFE'
        }
    }
})(TextField);


const useStylesBack = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
const Login = () => {
    const style = usestyles();
    const history = useHistory();
    const mailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const session = useSelector(state => state.login);
    console.log(session, 'sesion')
    const dispatch = useDispatch();
    const loadAll = Reload;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        email: '',
        password: '',
        repassword: '',
        phone: '',
        code: ''
    });
    const [open, setOpen] = useState(false);
    const backstyle = useStylesBack();
    const [recovery, setRecovery] = useState('');


    const {email, password, repassword, phone, code} = data;
    const [option, setOption] = useState('LOGIN');
    const [logged, setLogged] = useState({
        status: false,
        code: 0,
        token: '',
        s_token:""
    });
    const [sms, setSms] = useState({
        status: false,
        msg: ''
    })
    const handleChange = e => {
        if (loading)
            return;
        if (e.target.name === 'phone')
            if (e.target.value.split('').length > 10)
                return;
            else if (isNaN(e.target.value))
                return;
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeMode = () => {
        if (loading)
            return;
        if (option === 'RECOVERY')
            setOption('LOGIN')
        else
            setOption('RECOVERY');
    }
    const handleChangeModeReg = () => {
        if (loading)
            return;
        if (option === 'REGISTER')
            setOption('LOGIN')
        else
            setOption('REGISTER');
    }
    const handleLogin = async () => {
        if (!mailReg.test(email)) {
            dispatch(Actions.SET_MESSAGE('Correo no válido', 'error'));
            return;
        }
        if (password.split('').length === 0) {
            dispatch(Actions.SET_MESSAGE('Inserta contraseña', 'error'));
            return;
        }
        setLoading(true);
        const result = await logginService(email, password);
        console.log('Resultado logeo',result);
        setLoading(false);
        if (result.status === 'OK') {
            dispatch(Actions.LOG_IN(result.token,result.s_token, true));
            Cookie.set('xel_tk_client', result.token,{expires:3});
            Cookie.set('xel_usr_tk',result.s_token,{expires:3});
            history.push('/inicio')
        } else {
            if (result.code === 400) {
                setLogged({
                    status: true,
                    code: 400,
                    token: result.token,
                    s_token: result.s_token
                });
                setOption('LOGGED');
                return;
            }
            dispatch(Actions.SET_MESSAGE(result.description, 'error'))
        }
    }
    if (session.logged) {
        history.push('/inicio');
        return <Grid></Grid>
    }

    const handleRecoveryCode = e => {
        setRecovery(e.target.value);
    }
    const sendEmailRecovery = () => {
        setLoading(true);
        if (mailReg.test(data.email))
            fetch(API + '/user.email.recovery.json',
                {
                    method: "POST",
                    body: JSON.stringify({email: data.email})
                }).then(res => res.json())
                .then(res => {
                    console.log(res);
                    if (res.error) {
                        dispatch(Actions.SET_MESSAGE(res.error_description, 'error'));
                        setLoading(false);
                        return;
                    }
                    setOption('RECOVERY_PASSWORD');
                    setLoading(false);
                }).catch(err => {
                console.log(err);
                dispatch(Actions.SET_MESSAGE('Error, intenta de nuevo', 'error'));
                setLoading(false);
            });
        else
            dispatch(Actions.SET_MESSAGE('Inserta correo válido', 'error'));
    }

    const sendPasswordRecovery = () => {
        setLoading(true);

        if (code.split('').length === 0) {
            dispatch(Actions.SET_MESSAGE('Error, inserta tu código', 'error'));
            setLoading(false);
            return;
        }
        if (password.split('').length === 0 || repassword.split('').length === 0) {
            dispatch(Actions.SET_MESSAGE('Inserta contraseñas', 'error'));
            setLoading(false);
            return;
        }
        if (password !== repassword) {
            dispatch(Actions.SET_MESSAGE('Las contraseñas no coinciden', 'error'));
            setLoading(false);
            return;
        }
        if (mailReg.test(data.email))
            fetch(API + '/user.password.recovery.json',
                {
                    method: "POST",
                    body: JSON.stringify({
                        email: data.email,
                        code: code,
                        password: password
                    })
                }).then(res => res.json())
                .then(res => {
                    if (res.error) {
                        dispatch(Actions.SET_MESSAGE(res.error_description, 'error'));
                        setLoading(false);
                        return;
                    }
                    dispatch(Actions.SET_MESSAGE('Contraseña actualizada', 'success'));
                    setLoading(false);
                    setOption('LOGIN');
                    setData({
                        ...data,
                        code: ''
                    });
                }).catch(err => {
                console.log(err);
                dispatch(Actions.SET_MESSAGE('Error, intenta de nuevo', 'error'));
                setLoading(false);
            });
    }
    const verifyAndSend = () => {
        setLoading(true);
        let data = {
            msisdn: phone,
            verify: code
        };
        console.log('token',logged);

        let url = '';
        if (logged.status) {
            data.token = logged.s_token;
            console.log('send',data)
            url = 'https://inxel.mx/api/xel/v1/xel.pwa.user.logged.create.json'
        } else {
            data.email = email;
            data.password = password;
            url = 'https://inxel.mx/api/xel/v1/xel.pwa.user.create.json';
        }
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data)
        }).then(res => res.json()).then(res => {
            if (res.status) {
                setLoading(false);
                dispatch(Actions.LOG_IN(res.token, true));
                setData({
                    ...data,
                    code: ''
                });
            } else {
                dispatch(Actions.SET_MESSAGE(res.error_description, 'error'));
                setLoading(false);
            }
        }).catch(err => {
            console.log(err);
            dispatch(Actions.SET_MESSAGE('Error de conexion', 'error'))
        })
    }
    const sendMessage = () => {
        setLoading(true);
        if (phone.split('').length === 0) {
            dispatch(Actions.SET_MESSAGE('Número no válido', 'error'));
            setLoading(false);
            return;
        }
        fetch('https://inxel.mx/api/xel/v1/xel.pwa.user.msisdn.aW5YZWwyMDIwU0RGRkZGR0dHRw.json', {
            method: "POST",
            body: JSON.stringify({
                msisdn: phone,
                email: email
            })
        }).then(res => res.json()).then(res => {
            console.log(res)
            if (res.status) {
                setOption('CODE');
                if (res.status === 'OK')
                    setSms({
                        status: true,
                        msg: 'Introduce el código de verificación que se te envío a tu celular y a tu correo electrónico.'
                    });
                else if (res.status === 'OKYA')
                    setSms({
                        status: true,
                        msg: 'Introduce el código de verificación que se te envío con anterioridad a tu celular.'
                    });
                setLoading(false);
            } else {
                dispatch(Actions.SET_MESSAGE(res.error_description, 'error'));
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err);
            dispatch(Actions.SET_MESSAGE('Error de conexion', 'error'))
        })
    }

    const emailVerify = () => {
        if (!mailReg.test(email)) {
            dispatch(Actions.SET_MESSAGE('Correo no válido', 'error'));
            return;
        }
        if (password.split('').length === 0 || repassword.split('').length === 0) {
            dispatch(Actions.SET_MESSAGE('Inserta contraseñas', 'error'));
            return;
        }

        if (password === repassword) {
            setLoading(true);
            if (phone.split('').length < 10) {
                setLoading(false);
                dispatch(Actions.SET_MESSAGE('Número no válido', 'error'));
                return;
            }
            fetch('https://inxel.mx/api/xel/v1/xel.pwa.user.email.verify.json', {
                method: "POST",
                body: JSON.stringify({
                    email: email
                })
            }).then(res => res.json()).then(res => {
                console.log(res);
                if (res.status) {
                    sendMessage();
                } else {
                    dispatch(Actions.SET_MESSAGE(res.error_description, 'error'));
                    setLoading(false);
                }
            }).catch(err => {
                console.log(err);
                dispatch(Actions.SET_MESSAGE('Error de conexion', 'error'))
            });
        } else {

            dispatch(Actions.SET_MESSAGE('Las contraseñas no coinciden', 'error'));
        }
    }
    const handleSubmit = e => {
        e.preventDefault();
        switch (option) {
            case "LOGIN":
                handleLogin();
                break;
            case 'REGISTER':
                setLogged({
                    ...logged,
                    status: false,
                });
                emailVerify();
                break;
            case 'RECOVERY':
                sendEmailRecovery();
                break;
            case 'RECOVERY_PASSWORD':
                sendPasswordRecovery()
                break;
            case 'LOGGED':
                setOpen(true);
                break;
            case 'CODE':
                verifyAndSend();
                break;
            default:
                break;
        }
    }

    const handleClose = () => {
        sendMessage();
        setOpen(false);
    };

    return <Grid container className={style.contenedor} alignItems={'center'}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={style._container}>
                    <img src={Logo} alt={'inXel'} className={style.img}/>
                    <Typography variant={'body1'} style={{color: '#FEFEFE'}}>
                        Te conecta
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{height: 'fit-content'}} component={'form'} onSubmit={handleSubmit}>
                    <Grid container component={'form'} spacing={option === 'LOGIN' ? 2 : 3} justify={'center'}
                          style={{height: 'fit-content'}}>
                        <Backdrop open={open} className={backstyle.backdrop}>
                            <Card style={{
                                width: '70%',
                                maxWidth: 250,
                                minHeight: 230,
                                backgroundColor: '#FEFEFE',
                                padding: '1%',
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <Grid container>
                                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                        <img src={inXel} alt={'Logo'} style={{width: 100, height: '100%'}}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: XEL_FONT.semi_bold_weight, fontSize: '12pt'}}
                                                    variant={'body2'} align={'center'}>
                                            Se enviará un código de verificación al número inXel y al correo
                                            proporcionados.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}
                                          style={{display: "flex", justifyContent: "center", marginTop: 8}}>
                                        <Button onClick={handleClose} style={{
                                            color: '#FEFEFE',
                                            backgroundColor: XEL_COLORS.secondary,
                                            borderRadius: '30pt'
                                        }}>
                                            Aceptar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Backdrop>
                        {
                            option === 'CODE' ?
                                <Grid item xs={10}>
                                    <Typography className={style.msg_nop} align={'center'}>
                                        {sms.msg}
                                    </Typography>
                                </Grid> : ""
                        }
                        {
                            option === 'RECOVERY' ?
                                <Grid item xs={10}>
                                    <Typography className={style.msg_nop} align={'center'}>
                                        Inserta tu correo electrónico
                                    </Typography>
                                </Grid> : ""
                        }
                        {
                            option === 'RECOVERY_PASSWORD' ?
                                <Grid item xs={10}>
                                    <Typography className={style.msg_nop} align={'center'}>
                                        Inserta el código que se envío a tu correo electrónico e ingresa tu nueva
                                        contraseña
                                    </Typography>
                                </Grid> : ""
                        }
                        {
                            option === 'LOGGED' && logged.code === 400 ?
                                <Grid item xs={10}>
                                    <Typography className={style.msg_nop} align={'center'}>
                                        Tu cuenta no tiene un número inXel asociado, introduce el número que quieres
                                        asociar
                                    </Typography>
                                </Grid> : ""
                        }
                        {
                            option === 'CODE' || option === 'RECOVERY_PASSWORD' ?
                                <Grid item xs={10}>
                                    <XelTextField id="verify-code"
                                                  label="Código"
                                                  fullWidth
                                                  color={'secondary'}
                                                  variant={'filled'}
                                                  disabled={loading}
                                                  name={'code'}
                                                  type={'text'}
                                                  value={code}
                                                  onChange={handleChange}
                                    />
                                </Grid> : ''
                        }
                        {
                            option === 'REGISTER' ?
                                <Grid item xs={10}>
                                    <Typography className={style.msg_nop} align={'center'}>
                                        Inserta los datos que se solicitan a continuación.
                                    </Typography>
                                </Grid> : ""
                        }
                        {
                            option === 'LOGIN' || option === 'REGISTER' || option === 'RECOVERY' ?
                                <Grid item xs={10}>
                                    <XelTextField id="email-user"
                                                  label="Correo"
                                                  fullWidth
                                                  color={'secondary'}
                                                  variant={'filled'}
                                                  disabled={loading}
                                                  name={'email'}
                                                //  type={'email'}
                                                  value={email}
                                                  onChange={handleChange}
                                    />
                                </Grid> : ''
                        }

                        {
                            option === 'LOGIN' || option === 'REGISTER' || option === 'RECOVERY_PASSWORD' ?
                                <Grid item xs={10}>
                                    <XelTextField id="password-user"
                                                  label="Contraseña"
                                                  disabled={loading}
                                                  fullWidth
                                                  color={'secondary'}
                                                  variant={'filled'}
                                                  name={'password'}
                                                  type={'password'}
                                                  value={password}
                                                  onChange={handleChange}/>
                                </Grid> : ''
                        }
                        {
                            option === 'REGISTER' || option === 'RECOVERY_PASSWORD' ?
                                <Grid item xs={10}>
                                    <XelTextField id="password-user"
                                                  label="Repite contraseña"
                                                  disabled={loading}
                                                  fullWidth
                                                  color={'secondary'}
                                                  variant={'filled'}
                                                  name={'repassword'}
                                                  type={'password'}
                                                  value={repassword}
                                                  onChange={handleChange}/>
                                </Grid> : ''
                        }
                        {
                            option === 'REGISTER' || option === 'LOGGED' ?
                                <Grid item xs={10}>
                                    <XelTextField id="phone-user"
                                                  label="Teléfono inXel"
                                                  disabled={loading}
                                                  fullWidth
                                                  color={'secondary'}
                                                  variant={'filled'}
                                                  name={'phone'}
                                                  type={'text'}
                                                  value={phone}
                                                  onChange={handleChange}/>
                                </Grid> : ''
                        }


                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <Button
                                variant={'contained'}
                                type={'submit'}
                                startIcon={loading ? <CircularProgress color={'secondary'}/> : ''}
                                disabled={loading}
                            >
                                {
                                    loading ? 'Cargando' : option === 'LOGIN' ? 'Iniciar Sesión' : 'Enviar'
                                }
                            </Button>
                        </Grid>

                        {
                            option !== 'REGISTER' && option !== 'LOGGED' && option !== 'CODE' ?
                                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                    <Button type={'button'} style={{fontSize: 10, color: '#FEFEFE'}}
                                            onClick={handleChangeMode}

                                            disabled={loading}
                                    >
                                        {
                                            option === 'LOGIN' ? '¿Olvidaste tu contraseña?' : 'Iniciar sesión'
                                        }
                                    </Button>
                                </Grid> : ''
                        }
                        {
                            option !== 'RECOVERY' && option !== 'RECOVERY_PASSWORD' ?
                                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                    <Button type={'button'} style={{fontSize: 10, color: '#FEFEFE'}}
                                            onClick={handleChangeModeReg}

                                            disabled={loading}
                                    >
                                        {
                                            option === 'LOGIN' ? 'Registrarse' : 'Iniciar sesión'
                                        }
                                    </Button>
                                </Grid> : ''
                        }
                    </Grid>
                </Grid>
                {
                    /* <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                    <pwa-install descriptionheader={'Descripción'} explainer={'Esta aplicación se puede instalar en su PC o dispositivo móvil. Esto permitirá que esta aplicación web se vea y se comporte como cualquier otra aplicación instalada. Lo encontrarás en tus listas de aplicaciones y podrás anclarlo a tu pantalla de inicio, menús de inicio o barras de tareas. Esta aplicación web instalada también será capaz de interactuar de forma segura con otras aplicaciones y su sistema operativo.'}  iosinstallinfotext={'Pulsa el boton de compartir y luego "Añadir a pantalla principal"'} installbuttontext={'Instalar aplicación'}
                                 cancelbuttontext={'Cancelar instalación'} >

                    </pwa-install>

                </Grid>*/
                }
            </Grid>
        </Grid>

    </Grid>
}

export default Login;